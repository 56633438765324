import React, { useState } from 'react'
import Button from './Button'
import { useContext } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'
import fetchAPI from '../lib/fetchAPI'
import { useNavigate } from 'react-router'
import { EditDataContext } from '../contexts/EditDataContext'
import SelectLanguageToDelete from './SelectLanguageToDelete'

type Props = {
  id: string
  modelName: string
  url?: { [key: string]: string }
  get: () => void
  save: (
    type: string,
    languagesToDelete?: string[],
    onlyDeleteDuplicates?: boolean
  ) => void
}

const ActionsTab = ({ id, modelName, url, get, save }: Props) => {
  const { data } = useContext(EditDataContext)

  const { getLiteral, getToken, logout, appConfig, getUser } =
    useContext(GlobalContext)
  const [loading, setLoading] = useState('')
  const navigate = useNavigate()
  const user = getUser()

  const [translating, setTranslating] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [languagesToDelete, setLanguagesToDelete] = useState<string[]>([])
  const [onlyDeleteDuplicates, setOnlyDeleteDuplicates] = useState(true)

  const remove = async () => {
    if (!window.confirm('Delete?')) return

    setLoading('remove')

    const { valid } = await fetchAPI({
      url: 'model/delete',
      method: 'DELETE',
      body: {
        modelName,
        _id: id,
      },
      token: getToken(),
    })

    if (!valid) logout()

    setLoading('')

    navigate(`/admin/list/${modelName}`)
  }

  const duplicate = async () => {
    if (!window.confirm('Duplicate?')) return

    setLoading('duplicate')

    const { valid, data } = await fetchAPI({
      url: 'model/duplicate',
      body: {
        modelName,
        _id: id,
      },
      token: getToken(),
    })

    if (!valid) logout()

    setLoading('')

    navigate(`/admin/edit/${modelName}/${data.duplicatedId.insertedId}`)
  }

  const lock = async () => {
    if (!window.confirm(data?.stored?.locked ? 'Unock?' : 'Lock?')) return

    setLoading('locking')

    const { valid } = await fetchAPI({
      url: 'model/lock',
      body: {
        modelName,
        _id: id,
        lock: data?.stored?.locked ? false : true,
      },
      token: getToken(),
    })

    if (!valid) logout()

    setLoading('')

    get()
  }

  const preview = async (language: string, cleanCache = false) => {
    if (!url) return

    if (cleanCache) {
      setLoading(`preview${language}`)
      const res = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}custom-clean-cache`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: process.env.REACT_APP_PUBLIC_API_TOKEN as string,
          },
          body: JSON.stringify({ urls: [`${appConfig?.app?.url.substring(0, appConfig?.app?.url.length - 1)}${url[language]}`]  }),
        }
      );

      if (!res) logout()
      setLoading('')
    }

    const publicURL = new URL(`${appConfig?.app?.url}${url[language]}`)
    publicURL.searchParams.set('ptttoken', getToken() || '')
    window.open(publicURL.toString(), '_blank')
  }

  const handleTranslator = async () => {
    try {
      const confirmationMessage =
        'Are you sure you want to perform the translations?\n\nChanges will be automatically saved before translating.'
      if (!window.confirm(confirmationMessage)) {
        return
      }
      setTranslating(true)
      await save('translateAll')
    } catch (error) {
      console.error('An error occured trying to translate the content', error)
    }
    setTranslating(false)
  }

  const handleDeleteContent = async () => {
    try {
      if (languagesToDelete?.length === 0) {
        alert('Please, select the language that you want to delete')
        return
      }

      const confirmationMessage =
        'Are you sure you want to delete the content?\n\nChanges will be automatically saved before deleting.'
      if (!window.confirm(confirmationMessage)) {
        return
      }

      setDeleting(true)
      await save('deleteSelectedLang', languagesToDelete, onlyDeleteDuplicates)
    } catch (error) {
      console.error('An error occured trying to delete the content', error)
    }
    setDeleting(false)
  }

  return (
    <div>
      <div className="flex">
        <div className="mr-2">
          <Button
            loading={loading === 'delete'}
            theme="danger"
            disabled={data?.stored?.locked === true}
            onClick={() =>
              data?.stored?.locked ? alert(getLiteral('locked_text')) : remove()
            }
          >
            {getLiteral('delete')}
          </Button>
        </div>
        <div className="mr-2">
          <Button
            loading={loading === 'duplicate'}
            theme="outline-dark"
            onClick={duplicate}
          >
            {getLiteral('clone')}
          </Button>
        </div>
      </div>
      {url && (
        <div className="mt-8 ">
          <h2 className="text-xl">{getLiteral('urls')}</h2>
          <table className="w-full">
            <tbody>
              {Object.keys(url).map((key) => (
                <tr key={key} className="border-b border-grayLight">
                  <td className="py-2">{url[key]}</td>
                  <td className="flex justify-end py-2 text-right">
                    <div className="mr-2">
                      <Button
                        theme="outline-dark"
                        onClick={() => preview(key, true)}
                        loading={loading === `preview${key}`}
                      >
                        {getLiteral('refresh')}
                      </Button>
                    </div>
                    <div>
                      <Button theme="outline-dark" onClick={() => preview(key)}>
                        {getLiteral('preview')}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {user?.role === 'admin' && (
        <div className="mt-8 ">
          <h2 className="text-xl">{getLiteral('locking')}</h2>
          <table className="w-full">
            <tbody>
              <tr className="border-b border-grayLight">
                <td className="py-2">
                  {getLiteral(
                    data?.stored?.locked ? 'locked_text' : 'unlocked_text'
                  )}
                </td>
                <td className="flex justify-end py-2 text-right">
                  <div className="mr-2">
                    <Button
                      theme="outline-dark"
                      onClick={() => lock()}
                      loading={loading === `locking`}
                    >
                      {getLiteral(data?.stored?.locked ? 'unlock' : 'lock')}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {data?.modelConfig?.autoTranslatorModel && (
        <div className="my-8">
          <p className="text-xs font-light uppercase">Content</p>
          <p className="mb-1 font-light text-xxs">Text between {`{{ }}`} or {`[[ ]]`} will be avoided for translations.</p>
          <div className="flex p-4 bg-white border border-secondary">
            <div className="flex flex-col justify-between pr-4 border-r border-r-grayLight">
              <div className="mb-2 wght-semibold">Translate content</div>
              <div>
                <Button
                  theme="outline-dark"
                  onClick={handleTranslator}
                  loading={translating}
                  disabled={translating}
                >
                  {getLiteral('translate_all')}
                </Button>
              </div>
            </div>
            <SelectLanguageToDelete
              languages={data?.languages}
              languagesToDelete={languagesToDelete}
              setLanguagesToDelete={setLanguagesToDelete}
              onDeleteContent={handleDeleteContent}
              deleting={deleting}
              onlyDeleteDuplicates={onlyDeleteDuplicates}
              setOnlyDeleteDuplicates={setOnlyDeleteDuplicates}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ActionsTab
