import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import Button from "../components/Button";
import { Modal, CloseButton } from "../components/Modal";
import Container from "../components/Container";
import { GlobalContext } from "../contexts/GlobalContext";
import useLocalStorage from "../hooks/useLocalStorage";
import fetchAPI from "../lib/fetchAPI";
import printValue from "../lib/printValue";
import dragAndDropSortList from "../lib/dragAndDropSortList";
import dayjs from "dayjs";
import { debounce } from "lodash";

const modelName = "FragranceReview";

const FragranceReviewList = () => {
  const { appConfig, getToken, logout, getLiteral } = useContext(GlobalContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useLocalStorage(`${modelName}-sorting`, null);
  const [loading, setLoading] = useState(false);
  const [loadingButtons, setLoadingButtons] = useState(false);
  const [data, setData] = useState(false);
  const [title, setTitle] = useState(modelName);
  const [page, setPage] = useState(
    searchParams?.get("page") ? Number(searchParams.get("page")) : 0
  );
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const debounced = useRef(debounce((fn, value) => fn(value), 300));
  const [fragranceReviewSelected, setFragranceReviewSelected] = useState();
  const [emailTemplates, setEmailTemplates] = useState();
  const [templateId, setTemplateId] = useState();

  const getPerfumes = async (ids) => {
    if (ids.length === 0) return new Map([]);

    try {
      const { valid, data } = await fetchAPI({
        url: "external/query",
        body: {
          query: "FindPerfumesSimple",
          variables: {
            search: {
              lang: "en",
              ids: ids.join(","),
            },
          },
        },
        token: getToken(),
      });

      if (!valid) logout();

      return new Map(data.externalData.map((perfume) => [perfume.id, perfume]));
    } catch (err) {
      console.log("🚀 ~ getPerfumes ~ err:", err);
      return new Map([]);
    }
  };

  const getEmailTemplates = async () => {
    setLoadingButtons(true);
    const body = {
      modelName: "FragranceReviewEmailTemplate",
      page,
      itemsPerPage: 1_000,
    };

    const { valid, data } = await fetchAPI({
      url: "model/list",
      body,
      token: getToken(),
    });

    if (!valid) logout();

    setEmailTemplates(data.items);
    setLoadingButtons(false);
  };

  const getData = async () => {
    setLoading(true);

    const body = {
      modelName,
      page,
      filters,
    };

    body.itemsPerPage = appConfig?.app?.itemsPerPage || 50;

    if (sorting) {
      body.sorting = sorting;
    }

    const { valid, data } = await fetchAPI({
      url: "model/list",
      body,
      token: getToken(),
    });

    if (!valid) logout();

    setTitle(data.modelConfig.title);

    if (!filters.length) setFilters(data.modelConfig.filters);

    const perfumesMap = await getPerfumes(
      data.items.map(({ perfumeId }) => perfumeId)
    );

    data.items.forEach(
      (item) => (item.perfume = perfumesMap.get(item.perfumeId))
    );

    Object.keys(data.modelConfig.list).forEach((key) => {
      if (typeof data.modelConfig.list[key] === "object") {
        data.modelConfig.list[key] = data.modelConfig.list[key].label;
      }
    });

    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelName, sorting, page]);

  useEffect(() => {
    setSearchParams({
      page,
    });
  }, [page, setSearchParams]);

  const handleSort = (key) => {
    if (sorting && sorting.key === key) {
      if (sorting.direction === "asc") {
        setSorting(null);
      } else {
        setSorting({ key, direction: "asc" });
      }
    } else {
      setSorting({ key, direction: "desc" });
    }
  };

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      data?.modelConfig?.list?.position &&
      data?.items?.length
    ) {
      dragAndDropSortList({
        appConfig,
        getToken,
        page,
        modelName,
        getData,
      });
    }
  }, [data.items]);

  const handlefilters = ({ key, index }) => {
    setFilters((oldFilters) => {
      const newFilters = [...oldFilters];
      const currentFilter = newFilters[index];
      currentFilter.values[key] = !currentFilter.values[key];
      return newFilters;
    });
    debounced.current(getData);
  };

  const updateFragranceStatus = async (status, fragranceReviewId) => {
    setLoading(true);

    const { valid } = await fetchAPI({
      url: `model/update-fragrance-review`,
      method: "PUT",
      body: {
        modelName,
        fragranceReviewId:
          fragranceReviewId || fragranceReviewSelected._id || "",
        status,
        templateId,
      },
      token: getToken(),
    });

    if (!valid) logout();

    setFragranceReviewSelected();
    await getData();
    setTemplateId();
    setLoading(false);
  };

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Container>
          <div className="flex items-center justify-between mb-8">
            <h1 className="font-medium text-xxl">{title}</h1>
            <div>
              <Button
                className="absolute top-0 ring-0"
                onClick={() => setShowFilters((state) => !state)}
                theme="white"
              >
                {getLiteral("filters")}
                <span className="ml-2 ">▾</span>
              </Button>

              <Modal
                showModal={emailTemplates && fragranceReviewSelected}
                setShowModal={setFragranceReviewSelected}
                background="white"
              >
                <div className="relative max-w-md p-8 overflow-auto bg-white z-1 max-h-128">
                  <CloseButton setShowModal={setFragranceReviewSelected} />
                  <div className="p-4">
                    <h2 className="text-lg font-medium uppercase">
                      {getLiteral("manager_title_decline_modal")}
                    </h2>
                    <p className="mt-8">
                      {getLiteral("manager_text_decline_modal")}
                    </p>
                    <div className="flex items-end justify-end mt-8">
                      <div className="relative">
                        <select
                          defaultValue="-1"
                          className="inline-block px-4 py-1 pr-8 text-xs border rounded appearance-none cursor-pointer hover:border"
                          onChange={(e) => setTemplateId(e.target.value)}
                        >
                          <option value="-1" disabled>
                            {getLiteral("select_email_template")}
                          </option>
                          {(emailTemplates || []).map((emailTemplate) => (
                            <option
                              key={emailTemplate._id}
                              value={emailTemplate._id}
                            >
                              {emailTemplate.ref}
                            </option>
                          ))}
                        </select>
                        <span className="absolute inset-y-0 flex items-center mr-4 pointer-events-none end-0">
                          ▾
                        </span>
                      </div>

                      <div className="flex gap-2 ml-2 text-xs text-gray">
                        <Button
                          loading={loading}
                          disabled={
                            fragranceReviewSelected?.status === "declined" ||
                            loadingButtons ||
                            loading ||
                            !templateId
                          }
                          onClick={() => updateFragranceStatus("declined")}
                          theme="danger"
                        >
                          {getLiteral("manager_send")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              {showFilters && (
                <div className="relative">
                  {filters.map(({ label, values }, index) => (
                    <div
                      key={`${label}-${index}`}
                      className="absolute right-0 z-10 p-4 bg-white border rounded top-2 w-60"
                    >
                      <div className="relative flex flex-col flex-wrap pb-3 font-medium rounded cursor-pointer whitespace-nowrap">
                        {label}
                      </div>

                      {Object.keys(values).map((key) => (
                        <button
                          disabled={loading}
                          key={`filter-option-${index}-` + key}
                          className="flex rounded p-1.5 w-full items-center hover:bg-grayLight cursor-pointer"
                          onClick={() => handlefilters({ key, index })}
                        >
                          <input
                            disabled={loading}
                            className="mr-2 cursor-pointer"
                            type="checkbox"
                            id={`option-${key}`}
                            name={`option-${key}`}
                            checked={values[key]}
                          />
                          <label className="cursor-pointer select-none">
                            {key}
                          </label>
                        </button>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {loading && <div className="text-gray">Loading...</div>}
          {!loading && data && data.items.length === 0 && (
            <div>Nothing to list</div>
          )}
          {!loading && data && data.items.length > 0 && (
            <table className="w-full mb-8">
              <thead className="w-full text-left bg-white border-b border-grayLight">
                <tr>
                  {Object.keys(data.modelConfig.list).map(
                    (key) =>
                      key !== "_id" && (
                        <th key={key} className="p-2">
                          <span
                            className="relative text-xs font-normal uppercase cursor-pointer"
                            onClick={() => handleSort(key)}
                          >
                            {data.modelConfig.list[key]}
                            {sorting && sorting.key === key && (
                              <span className="text-xs font-normal l-2">
                                {sorting.direction === "asc" ? "↑" : "↓"}
                              </span>
                            )}
                          </span>
                        </th>
                      )
                  )}
                  <th
                    align="right"
                    className="py-2 pr-8 text-xs font-normal uppercase"
                  >Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.items.map((item,index) => (
                  <tr
                    key={item._id}
                    id={item._id}
                    data-position={item.position}
                    className={`relative ${index % 2 === 0 ? 'bg-white' : 'bg-grayLighter'} border-b border-grayLight`}
                  >
                    {Object.keys(data.modelConfig.list).map((key) => {
                      if (key === "position") {
                        return (
                          <td className="flex items-center px-2 py-3 cursor-pointer handle h-fullk">
                            <div className="text-gray">
                              <div className="h-1 rotate-90 ">···</div>
                            </div>
                            <p className="mr-6">{printValue({ item, key })}</p>
                          </td>
                        );
                      }

                      if (key === "perfumeId") {
                        const perfume = printValue({ item, key: "perfume" });
                        
                        if (!perfume) return <td key={key} className="p-2 py-3">Perfume not found</td>
                        return (
                          <td key={key} className="p-2 py-3 max-w-column">
                            <a
                              className="cursor-pointer text-gray wght-semibold hover:text-primary"
                              target="_blank"
                              href={`${process.env.REACT_APP_WEB_URL}en/fragrances/${perfume.slug}`}
                              rel="noreferrer"
                            >
                              {perfume.name}
                            </a>
                          </td>
                        );
                      }
                      if (key === "review") {
                        return (
                          <td key={key} className="p-2 py-3 whitespace-nowrap">
                            <textarea
                              className="w-full p-2 mr-6 bg-transparent border border-grayLighter" // Agregando clases para padding, ancho completo y borde gris
                              cols="30"
                              rows="3"
                              disabled
                            >
                              {printValue({ item, key })}
                            </textarea>
                          </td>
                        );
                      }

                      if (key === "createdAt") {
                        const parseDate = dayjs(printValue({ item, key }));
                        const date = parseDate.format("YYYY-MM-DD HH:mm:ss");
                        return (
                          <td key={key} className="p-2 py-3 whitespace-nowrap">
                            <p className="mr-6">{date}</p>
                          </td>
                        );
                      }

                      if (!["_id", "publication"].includes(key)) {
                        return (
                          <td key={key} className="p-2 py-3">
                            {typeof item[key] !== "undefined" &&
                              printValue({ item, key })}
                          </td>
                        );
                      }

                      return <></>;
                    })}
                    <td align="right" className="p-2 py-3 text-right">
                      <div className="flex items-center justify-end">
                        {data?.modelConfig?.hasPublication && (
                          <div
                            className={`mr-4 px-2 rounded bg-${item?.publication?.status}Soft inline-flex items-center text-xs`}
                          >
                            <div
                              className={`mr-2 w-2 h-2 rounded-full bg-${item?.publication?.status}`}
                            ></div>
                            <span>{getLiteral(item?.publication?.status)}</span>
                          </div>
                        )}
                        <div className="flex items-center justify-end">
                          <div className="relative flex flex-col gap-3 text-xs text-gray">
                            <Button
                              disabled={
                                item.status === "declined" || loadingButtons
                              }
                              onClick={async () => {
                                if (!emailTemplates) await getEmailTemplates();
                                setFragranceReviewSelected(item);
                              }}
                              theme="danger"
                            >
                              {getLiteral("decline")}
                            </Button>
                            <Button
                              disabled={
                                ["declined", "approved", "reported"].includes(
                                  item.status
                                ) || loadingButtons
                              }
                              onClick={() =>
                                updateFragranceStatus("approved", item._id)
                              }
                              theme="outline-green"
                            >
                              {getLiteral("approve")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!loading && data.numberOfPages > 1 && data.items.length > 0 && (
            <div className="flex justify-end my-8 text-sm text-gray">
              <div className="flex">
                {page - 3 > 0 && <span className="mr-2">...</span>}
                {Array.from({ length: data.numberOfPages }).map((_, index) =>
                  Math.abs(index - page) < 5 ? (
                    <span
                      key={index}
                      onClick={() => setPage(index)}
                      className={`mr-2 cursor-pointer ${
                        index === page ? "text-black" : ""
                      }`}
                    >
                      {index + 1}
                    </span>
                  ) : null
                )}
                {page + 3 < data.numberOfPages && <span>...</span>}
              </div>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default FragranceReviewList;
