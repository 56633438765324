import generateToken from "./generateToken";
import { AppConfig } from "../types";

const updateMeta = async (
  token: string,
  fileInfo: { [key: string]: unknown }
) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}media/create`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(fileInfo),
  });

  const responseData = await res.json();

  return {
    ...responseData,
  };
};

const upload = async (
  type: string,
  token: string,
  appConfig: AppConfig,
  file: File,
  callback = () => {},
  errorCallback = () => {}
): Promise<{ [key: string]: unknown } | null> => {
  const validations: { [key: string]: string[] } = {
    image: ["image/jpeg", "image/png", "image/gif", "image/webp"],
    file: [],
  };

  const { type: fileType } = file;

  if (
    validations[fileType] &&
    validations[fileType].length > 0 &&
    !validations[fileType].includes(type)
  ) {
    alert(
      "Invalid file type. Allowed types: " + Object.keys(validations).join(", ")
    );
    return null;
  }

  const name =
    typeof file !== "undefined" && typeof file.name === "string"
      ? file.name
      : "";
  const parts = name.split(".");
  let extension;
  if (parts.length > 0) {
    extension = parts.pop();
    if (extension) {
      extension = extension.toLowerCase();
    }
    if (!extension) {
      errorCallback();
      return null;
    }
  }

  if (file && Number(file.size) / 1024 / 1024 > 10) {
    alert("Max file size 10MB");
    errorCallback();
    return null;
  }

  try {
    const name = generateToken(40);
    const fileInfo: { [key: string]: unknown } = {
      name,
      fullName: `${name}.${extension}`,
      extension,
      type: file.type,
      size: file.size,
      originalName: file.name,
      width: 0,
      height: 0,
    };

    if (
      ["image/jpeg", "image/png", "image/webp"].includes(
        fileInfo.type as string
      )
    ) {
      const dimensions: { [key: string]: number } = await new Promise(
        (resolve, reject) => {
          const img = new Image();
          img.src = window.URL.createObjectURL(file);
          img.onload = async () => {
            resolve({
              width: img.width,
              height: img.height,
            });
          };
        }
      );
      fileInfo.width = dimensions.width;
      fileInfo.height = dimensions.height;
    }

    const requestUploadResponse = await fetch(
      `${process.env.REACT_APP_API_URL}media/request-upload`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fileName: fileInfo.fullName,
          fileType: file.type,
          width: fileInfo.width,
        }),
      }
    );

    const data = await requestUploadResponse.json();

    const uploadToS3Response = await fetch(data.url, {
      method: "PUT",
      body: file,
    });

    if (uploadToS3Response.status !== 200) {
      throw new Error("Could not upload to AWS");
    }

    fileInfo.resizable = data.resizable;
    fileInfo.quality = data.quality;
    fileInfo.sizes = data.sizes;
    fileInfo.pathTemplate = data.pathTemplate;

    const fileData = await updateMeta(token, fileInfo);
    return fileData;
  } catch (e) {
    console.log("Error uploading", e);
    return {};
  }
};

export default upload;
