/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Actions from "../components/Actions";
import Button from "../components/Button";
import Container from "../components/Container";
import { GlobalContext } from "../contexts/GlobalContext";
import useLocalStorage from "../hooks/useLocalStorage";
import fetchAPI from "../lib/fetchAPI";
import printValue from "../lib/printValue";
import dragAndDropSortList from "../lib/dragAndDropSortList";
import TranslateLiterals from "../components/TranslateLiterals";
import throttle  from "../lib/throttle";
import { userHasPermission } from "../lib/permissions";

const List = () => {
  const { name: modelName } = useParams();
  const navigate = useNavigate();
  const { appConfig, getToken, logout, getLiteral, getUser } = useContext(GlobalContext);
  const user = getUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useLocalStorage(`${modelName}-sorting`, null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [title, setTitle] = useState(modelName);
  const [page, setPage] = useState(searchParams?.get('page') ? Number(searchParams.get('page')) : 0);
  const [searchValue, setSearchValue] = useState();

  const getData = async () => {
    setLoading(true);

    const body = {
      modelName,
      page,
    };

    body.itemsPerPage = appConfig?.app?.itemsPerPage || 50;

    if (sorting) {
      body.sorting = sorting;
    }

    if (searchValue) {
      body.search = searchValue;
    }

    const { valid, data } = await fetchAPI({
      url: "model/list",
      body,
      token: getToken(),
    });

    if (!valid) logout();

    if (data.error) {
      throw new Error('API error: ', data.error)
    }

    setTitle(data.modelConfig.title);
    Object.keys(data.modelConfig.list).forEach((key) => {
      if (typeof data.modelConfig.list[key] === "object") {
        data.modelConfig.list[key] = data.modelConfig.list[key].label;
      }
    });
    setData(data);

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [modelName, sorting, page]);

  useEffect(() => {
    if (!userHasPermission({ appConfig, modelName, user })) {
      window.location.href = "/";
    }
    setSearchValue();
    setPage(0);
  }, [modelName]);

  useEffect(() => {
    setSearchParams({
      page,
    });
  }, [page, setSearchParams]);

  const handleSort = (key) => {
    if (sorting && sorting.key === key) {
      if (sorting.direction === "asc") {
        setSorting(null);
      } else {
        setSorting({ key, direction: "asc" });
      }
    } else {
      setSorting({ key, direction: "desc" });
    }
  };

  const goToDetail = (id) => {
    navigate(`/admin/edit/${modelName}/${id}`, {
      state: { page },
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && data?.modelConfig?.list?.position && data?.items?.length) {
      dragAndDropSortList({
        appConfig,
        getToken,
        page,
        modelName,
        getData,
      })
    }
  }, [data.items])

  useEffect(() => {
    if (typeof searchValue === 'string') {
      setPage(0);
      getData();
    }
  }, [searchValue])

  const search = (e) => {
    const value = e.target.value;
    throttle({
      callback: setSearchValue(value),
      time: 500
    })()
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <Container>
          <div className="flex items-center justify-between mb-8">
            <h1 className="font-medium text-xxl">{title}</h1>
            <Button
              to={{
                pathname: `/admin/edit/${modelName}`,
              }}
              theme={"dark"}
            >
              Add
            </Button>
          </div>
          
          <div className="mb-2">
            <input
              type="text"
              placeholder={`Search ${title}`}
              className="p-1 mr-1 text-xs bg-white border rounded border-grayLight"
              onChange={search}
              value={searchValue || ''}
            />
            {/* clean filters button */}
            <Button
              onClick={() => setSearchValue('')}
              theme={"outline-gray"}
              className="border-grayLight"
            >
              Clear
            </Button>
          </div>

          {loading && <div className="mt-4 text-gray">Loading...</div>}
          {!loading && data && data.items.length === 0 && (
            <div>Nothing to list</div>
          )}

          {/************
          ** Warning: This is a dangerous action, only available to developers.
          *************/}
          {!loading && modelName === 'PttLiteral' && (
            <TranslateLiterals
              languages={appConfig?.languages}
              token={getToken()}
              logout={logout}
              navigate={navigate}
            />
          )}

          {!loading && data && data.items.length > 0 && (
            <table className="w-full mb-8">
              <thead className="w-full text-left bg-white border-b border-grayLight">
                <tr>
                  {Object.keys(data.modelConfig.list).map(
                    (key) =>
                      key !== "_id" && (
                        <th key={key} className="p-2">
                          <span
                            className="relative text-xs font-normal uppercase cursor-pointer"
                            onClick={() => handleSort(key)}
                          >
                            {data.modelConfig.list[key]}
                            {sorting && sorting.key === key && (
                              <span className="text-xs font-normal l-2">
                                {sorting.direction === "asc" ? "↑" : "↓"}
                              </span>
                            )}
                          </span>
                        </th>
                        
                      )
                  )}
                  <th
                    align="right"
                    className="py-2 text-xs font-normal uppercase"
                  >actions</th>
                </tr>
              </thead>
              <tbody>
                {data.items.map(item => (
                  <tr
                    key={item._id}
                    id={item._id}
                    data-position={item.position}
                    className="relative bg-white border-b border-grayLight hover:bg-grayLighter"
                  >
                    {Object.keys(data.modelConfig.list).map(
                      (key) =>
                        {
                          if (key === "position") {
                            return <td className="flex items-center px-2 py-3 cursor-pointer handle h-fullk">
                              <div className="text-gray">
                                <div className="h-1 rotate-90 ">···</div>
                              </div>
                              <p className="mr-6">{printValue({ item, key })}</p>
                            </td>
                          }

                          if (!["_id", "publication"].includes(key) || key === "publication.status") {
                            return <td
                              key={key}
                              className="p-2 py-3 cursor-pointer"
                              onClick={() => (key !== 'position' || !data?.modelConfig?.list?.position) && goToDetail(item._id)}
                            >
                              {(typeof item[key] !== "undefined" || key === "publication.status" || key.includes(".")) &&
                                printValue({ item, key })}
                            </td>
                          }

                          return <></>
                        }
                    )}
                    <td align="right" className="p-2 py-3 text-right">
                      <div className="flex items-center justify-end">
                        {data?.modelConfig?.hasPublication && (
                          <div
                            className={`mr-4 px-2 rounded bg-${item?.publication?.status}Soft inline-flex items-center text-xs`}
                          >
                            <div
                              className={`mr-2 w-2 h-2 rounded-full bg-${item?.publication?.status}`}
                            ></div>
                            <span>{getLiteral(item?.publication?.status)}</span>
                          </div>
                        )}
                        <div className="flex items-center justify-end">
                          <Actions
                            page={page}
                            modelName={modelName}
                            id={item._id}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!loading && data.numberOfPages > 1 && data.items.length > 0 && (
            <div className="flex justify-end my-8 text-sm text-gray">
              <div className="flex">
                {page - 3 > 0 && <span className="mr-2">...</span>}
                {Array.from({ length: data.numberOfPages }).map((_, index) =>
                  Math.abs(index - page) < 5 ? (
                    <span
                      key={index}
                      onClick={() => setPage(index)}
                      className={`mr-2 cursor-pointer ${
                        index === page ? "text-black" : ""
                      }`}
                    >
                      {index + 1}
                    </span>
                  ) : null
                )}
                {page + 3 < data.numberOfPages && <span>...</span>}
              </div>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default List