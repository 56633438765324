import { createContext, useEffect, useState } from "react";
import {
  DataType,
  PttModelField,
  StoredDataType,
  PttFieldError,
} from "../types";
import Errors from "../components/fields/Errors";
import generateToken from "../lib/generateToken";

function flattenObject(
  ob: { [key: string]: any },
  prefix?: string,
  result?: { [key: string]: any }
) {
  result = result || {};

  // Preserve empty objects and arrays, they are lost otherwise
  if (
    prefix &&
    typeof ob === "object" &&
    ob !== null &&
    Object.keys(ob).length === 0
  ) {
    result[prefix] = Array.isArray(ob) ? [] : {};
    return result;
  }

  prefix = prefix ? prefix + "." : "";

  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      // Only recurse on true objects and arrays, ignore custom classes like dates
      if (
        typeof ob[i] === "object" &&
        (Array.isArray(ob[i]) ||
          Object.prototype.toString.call(ob[i]) === "[object Object]") &&
        ob[i] !== null
      ) {
        // Recursion on deeper objects
        flattenObject(ob[i], prefix + i, result);
      } else {
        result[prefix + i] = ob[i];
      }
    }
  }
  return result;
}

type ModeType = "create" | "edit";

type EditDataContextType = {
  name?: string;
  setName: (name: string) => void;
  language?: string;
  setLanguage: (language: string) => void;
  data?: DataType;
  setData: (data?: DataType) => void;
  mode: ModeType;
  setMode: (mode: ModeType) => void;
  errors: { [key: string]: unknown };
  updateStored: (
    field: PttModelField,
    value: { [key: string]: unknown }
  ) => void;
  storedData?: StoredDataType;
  getValue: ({
    field,
    defaultValue,
  }: {
    field: PttModelField;
    defaultValue?: any;
  }) => any;
  getErrors: ({ field }: { field: PttModelField }) => string[];
  getLanguage: ({ field }: { field: PttModelField }) => string;
  ready: boolean;
  getLanguages: () => DataType["languages"];
  showErrors: boolean;
  hasErrors: (fields?: PttModelField[]) => boolean;
  setShowErrors: (showErrors: boolean) => void;
  setStoredData: (storedData: StoredDataType) => void;
};

export function useEditDataContext(): EditDataContextType {
  const [ready, setReady] = useState(false);
  const [mode, setMode] = useState<ModeType>("create");
  const [language, setLanguage] = useState<string>("default");
  const [name, setName] = useState<string | undefined>(undefined);
  const [storedData, setStoredData] = useState<StoredDataType>();
  const [data, setData] = useState<DataType | undefined>(undefined);
  const [errors, setErrors] = useState<{ [key: string]: unknown }>({});
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (data && data.stored) {
      setStoredData(data.stored);
    }
    if (data && data.languages) {
      setLanguage(Object.keys(data.languages)[0]);
    }
    if (!data) {
      setReady(false);
    }
  }, [data]);

  useEffect(() => {
    if (storedData && language) {
      setReady(true);
    }
  }, [storedData, language]);

  const getParentData = (field: PttModelField, readonly?: Boolean) => {
    if (!data || !storedData) return;

    const parentFields: PttModelField[] = [];
    let parentField = field?.parentField;
    while (parentField) {
      parentFields.push(parentField);
      parentField = parentField?.parentField;
    }

    // We use the getParentData function both write and read data. If we read, we don't want to alter the original object
    let parentData = readonly
      ? { ...storedData }
      : (storedData as { [key: string]: unknown });
    for (const parentField of [...parentFields].reverse()) {
      if (!parentData[parentField.name]) {
        parentData[parentField.name] =
          typeof parentField?.index !== "undefined" ? [] : {};
      }
      if (
        typeof parentField?.index !== "undefined" &&
        Array.isArray(parentData[parentField.name])
      ) {
        const modelsArr = parentData[parentField.name] as {
          [key: string]: unknown;
        }[];
        if (!modelsArr[parentField.index as number]) {
          const key = generateToken(30) as string;
          (parentData[parentField.name] as { [key: string]: unknown }[])[
            parentField.index as number
          ] = {
            key,
            id: key,
            modelName: field.modelName,
          };
        }
        parentData = (
          parentData[parentField.name] as { [key: string]: unknown }[]
        )[parentField.index as number] as { [key: string]: unknown };
      } else {
        parentData = parentData[parentField.name] as { [key: string]: unknown };
      }
    }

    return parentData;
  };

  const updateStored = (
    field: PttModelField,
    value: { [key: string]: unknown }
  ) => {
    if (!data || !storedData) return;

    const parentData = getParentData(field);
    if (!parentData) return;

    const valueToStore = value.hasOwnProperty("default")
      ? (value.default as string)
      : (value as { [key: string]: unknown });

    let extraData: { [key: string]: any } = {
      mode,
    };
    if (
      field?.validations &&
      Array.isArray(field?.validations) &&
      (field?.validations as string[]).includes("password")
    ) {
      const repeatedValue = getValue({
        field: { ...field, name: `${field.name}_repeated` },
      }) as { [key: string]: unknown };
      const repeatedValueToStore = repeatedValue.hasOwnProperty("default")
        ? (repeatedValue.default as string)
        : (repeatedValue as { [key: string]: unknown });
      extraData = {
        mode,
        repeated: repeatedValueToStore,
      };
    }

    const errs = Errors(
      valueToStore,
      (field?.validations as PttFieldError[]) || [],
      (field?.translatable as boolean) || false,
      data.languages,
      extraData
    );
    errors[field.name] = errs;
    setErrors({ ...errors });

    parentData[field.name] = valueToStore;

    setStoredData({ ...storedData });
  };

  const getValue = ({
    field,
    defaultValue,
  }: {
    field: PttModelField;
    defaultValue?: any;
  }) => {
    const parentData = getParentData(field, true);
    if (!parentData) return;

    let value;
    if (typeof parentData[field.name] !== "undefined") {
      if (field.translatable) {
        value = parentData[field.name] as { [key: string]: unknown };
      } else {
        value = {
          default: parentData[field.name],
        };
      }
    } else {
      if (field.translatable) {
        value = {};
      } else {
        value = {
          default: defaultValue || undefined,
        };
      }
    }

    return value;
  };

  const getErrors = ({ field }: { field: PttModelField }) => {
    return (errors[field.name] as string[]) || [];
  };

  const hasErrors = (fields?: PttModelField[]) => {

    if (fields) {
      const errs = flattenObject(errors);
      fields.forEach(field => {
        const valueToStore = storedData ? (storedData[field.name] as string | { [key: string]: unknown } | null) : null;

        let extraData: { [key: string]: any } = {
          mode,
        };
        if (
          field?.validations &&
          Array.isArray(field?.validations) &&
          (field?.validations as string[]).includes("password")
        ) {
          const repeatedValue = getValue({
            field: { ...field, name: `${field.name}_repeated` },
          }) as { [key: string]: unknown };
          const repeatedValueToStore = repeatedValue.hasOwnProperty("default")
            ? (repeatedValue.default as string)
            : (repeatedValue as { [key: string]: unknown });
          extraData = {
            mode,
            repeated: repeatedValueToStore,
          };
        }

        const fieldErrs = Errors(
          valueToStore,
          (field?.validations as PttFieldError[]) || [],
          (field?.translatable as boolean) || false,
          getLanguages(),
          extraData,
        );
        errs[field.name] = fieldErrs
      })
      setErrors({ ...errs });
      return Object.values(errs).some((err) => err.length > 0);
    }
    
    if (errors) {
      const errs = flattenObject(errors);
      return Object.values(errs).some((err) => err.length > 0);
    }
    return false;
  };

  const getLanguage = ({ field }: { field?: PttModelField }) => {
    if (!field) return language;
    return field?.translatable ? language : "default";
  };

  const getLanguages = () => {
    return data?.languages || {};
  };

  return {
    mode,
    setMode,
    language,
    setLanguage,
    name,
    setName,
    data,
    setData,
    errors,
    updateStored,
    storedData,
    getValue,
    getErrors,
    getLanguage,
    ready,
    getLanguages,
    showErrors,
    setShowErrors,
    hasErrors,
    setStoredData,
  };
}

const EditDataContextValue: EditDataContextType = {
  mode: "create",
  setMode: () => {},
  language: undefined,
  setLanguage: () => {},
  name: undefined,
  setName: () => {},
  data: undefined,
  setData: () => {},
  errors: {},
  updateStored: () => {},
  storedData: undefined,
  getValue: () => {},
  getErrors: () => [],
  getLanguage: () => "default",
  ready: false,
  getLanguages: () => ({}),
  showErrors: false,
  hasErrors: (fields?: PttModelField[]) => false,
  setShowErrors: () => {},
  setStoredData: () => {},
};

export const EditDataContext =
  createContext<EditDataContextType>(EditDataContextValue);
