import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Form from "../components/fields/Form";
import { EditDataContext } from "../contexts/EditDataContext";
import { GlobalContext } from "../contexts/GlobalContext";

const Recovery = () => {
  const { getLiteral } = useContext(GlobalContext);
  const { storedData, hasErrors, setShowErrors, setData, data } =
    useContext(EditDataContext);

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    setData({
      stored: {
        modelName: "Login",
      },
      languages: {
        en: "en",
      },
      models: {
        Login: [
          {
            name: "email",
            type: "text",
            options: {
              label: "Email",
              style: {
                width: 4,
              },
            },
          },
        ],
      },
    });
  }, [setData]);

  const send = async () => {
    if (!storedData) return;

    setShowErrors(false);
    setLoading(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}session/recover-password`,
        {
          method: "POST",
          body: JSON.stringify({
            email: storedData.email,
          }),
        }
      );

      const responseData = await res.json();

      if (res.status !== 200) {
        setErrorMessage(responseData.error);
        return;
      }

      setEmailSent(true);
      navigate("/reset");
    } catch (err) {
      if (hasErrors()) setShowErrors(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4 bg-white md:w-1/2 lg:w-1/4">
        <h1 className="text-xl font-medium">
          {getLiteral("recover_password_title")}
        </h1>
        <h2 className="font-light text-gray">
          {getLiteral("recover_password_text")}
        </h2>

        <div className="my-8">
          {data && <Form fields={data.models.Login} name={"Login"} />}
        </div>

        {errorMessage && <div className="mb-4 text-red">{errorMessage}</div>}
        {emailSent && (
          <div className="mb-4">
            {getLiteral("recover_password_email_sent")}
          </div>
        )}
        <div className="items-center justify-between md:flex">
          <Button onClick={send} theme="dark" loading={loading}>
            {getLiteral("recover_password_cta")}
          </Button>
          <Link to={`/login`} className="text-xs cursor-pointer text-gray">
            {getLiteral("cancel")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Recovery;
