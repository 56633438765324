import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppConfigMenuItemType } from "../types";
import Container from "../components/Container";
import { GlobalContext } from "../contexts/GlobalContext";
import fetchAPI from "../lib/fetchAPI";
import Cache from "../components/Cache";

const Dashboard = () => {
  const { appConfig, getUser, getToken, logout } = useContext(GlobalContext);

  const user = getUser();

  const cleanCache = async () => {
    if (!window.confirm("Empty cache?")) return;
    const { valid } = await fetchAPI({
      url: "model/clean-cache",
      body: {
        modelName: "all",
      },
      token: getToken(),
    });
    if (!valid) logout();
    alert("Cache emptied");
  };

  return (
    <div>
      <Container>
        <>
          <h1 className="font-medium text-xxl">Dashboard</h1>
          {appConfig && appConfig.menu && (
            <ul className="grid-cols-3 gap-4 md:grid">
              {appConfig.menu.map((item: AppConfigMenuItemType) => (
                <li
                  className="p-4 mb-4 border border-grayLight md:mb-0"
                  key={item.model}
                >
                  <h2 className="block mb-4">
                    <NavLink
                      className={`border-solid hover:text-primary border-primary font-medium`}
                      to={item.path || `/admin/list/${item.model}`}
                    >
                      {item.title}
                    </NavLink>
                  </h2>
                  <div className="flex">
                    <NavLink
                      className={`border-solid mr-4 hover:text-primary border-primary`}
                      to={item.path || `/admin/list/${item.model}`}
                    >
                      List
                    </NavLink>
                    <NavLink
                      className={`border-solid hover:text-primary border-primary`}
                      to={item.path || `/admin/edit/${item.model}`}
                    >
                      Add
                    </NavLink>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {['admin','developer'].includes(user?.role as string) && <Cache userRole={user?.role || ''} />}
        </>
      </Container>
    </div>
  );
};

export default Dashboard;
