import React, { useState } from 'react'
import Button from './Button'
import fetchAPI from '../lib/fetchAPI'
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router'
import { GlobalContext } from "../contexts/GlobalContext"
import { EditDataContext } from '../contexts/EditDataContext'
import { useContext } from "react"

const Actions = ({
  modelName,
  id,
  page = "",
}: {
  modelName: string;
  id: string;
  page: string;
}) => {
  const { data } = useContext(EditDataContext);
  const { getLiteral, getToken, logout } = useContext(GlobalContext);
  const [loading, setLoading] = useState('');
  const navigate = useNavigate();

  const duplicate = async () => {
    if (!window.confirm('Duplicate?')) return

    setLoading('duplicate')

    const { valid, data } = await fetchAPI({
      url: 'model/duplicate',
      body: {
        modelName,
        _id: id,
      },
      token: getToken(),
    })

    if (!valid) logout()

    setLoading('')

    navigate(`/admin/edit/${modelName}/${data.duplicatedId.insertedId}`)
  }

  const remove = async () => {
    if (!window.confirm('Delete?')) return;

    setLoading('remove');

    const { valid } = await fetchAPI({
      url: 'model/delete',
      method: 'DELETE',
      body: {
        modelName,
        _id: id,
      },
      token: getToken(),
    });

    if (!valid) {
      logout();
    } else {
      setLoading('');
      window.location.reload();
    }
  };

  return (
    <div className="relative flex text-xs text-gray">
      <Link
        className="mr-4 cursor-pointer hover:text-primary"
        to={{
          pathname: `/admin/edit/${modelName}/${id}`,
          search: `page=${page}`
        }}
      >
        {getLiteral("edit")}
      </Link>
      <span
        className={`mr-4 cursor-pointer hover:text-primary`}
        onClick={() => duplicate()}
      >
        Clone
      </span>
      <span
        className={`mr-4 cursor-pointer hover:text-primary`}
        style={{
          opacity: loading ? 0.5 : 1,
          pointerEvents: loading ? 'none' : 'auto',
        }}
        onClick={remove}
      >
        Delete
      </span>
    </div>
  );
};

export default Actions;
