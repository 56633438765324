type FetchAPIType = {
  token?: string,
  url: string,
  method?: string,
  body?: {[key: string]: any}
  isPublic?: boolean,
}

const fetchAPI = async ({
  token, 
  url, 
  method = 'POST',
  body = {},
  isPublic = false,
}: FetchAPIType) => {

  if (!token && !isPublic) {
    return {
      valid: false, 
      data: null,
    }
  }

  const options: {[key: string]: any} = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  }

  if (token) {
    options.headers.Authorization = `Bearer ${token}`
  }

  if (method !== 'GET') {
    options.body = JSON.stringify(body)
  }

  const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options)

  const data = await res.json()

  if (res.status === 403) {
    return {
      valid: false,
      data: {},
      status: res.status,
    }
  }

  return {
    valid: true,
    status: res.status,
    data,
  }
}

export default fetchAPI