import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../contexts/GlobalContext";

const Cache = ({userRole}: {userRole: string}) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [types, setTypes] = useState([] as string[]);
  const [feedback, setFeedback] = useState("");
  const { getToken, appConfig } = useContext(GlobalContext);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setTypes([...types, e.target.name]);
    } else {
      setTypes(types.filter((type) => type !== e.target.name));
    }
  };

  const openPreview = () => {
    const publicURL = new URL(`${appConfig?.app?.url}/en`);
    publicURL.searchParams.set("ptttoken", getToken() || "");
    window.open(publicURL.toString(), "_blank");
  };

  const deleteCache = async () => {
    if (loading) return;

    if (types.length === 0 && !url) {
      return;
    }

    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_PUBLIC_API_URL}custom-clean-cache`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_PUBLIC_API_TOKEN as string,
        },
        body: JSON.stringify({ urls: url.split(","), types }),
      }
    );
    if (res.status === 200) {
      try {
        const data = await res.json();
        if (data?.total === 0) {
          setFeedback("Nothing to uncache");
        } else {
          setFeedback(`${data?.total} items uncached successfully.`);
        }
      } catch (error) {
        setFeedback("There was an error. Please try again.");
      }
    } else {
      setFeedback("There was an error. Please try again.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (feedback) {
      setTimeout(() => {
        setFeedback("");
      }, 3000);
    }
  }, [feedback]);

  return (
    <div className="mt-4">
      <h3 className="text-xl font-medium">Cache</h3>
      <div className="p-4 mb-4 border border-grayLight">
        <h4 className="text-base2">Delete by URL (comma separated)</h4>
        <input
          className="w-full p-2 mb-2 border border-solid border-grayLight"
          type="text"
          placeholder="URL to delete"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        {url && (
          <div className="inline-flex flex-wrap">
            {url.split(",").map((u) => (
              <span
                key={u}
                className="inline px-1 py-px mb-2 mr-2 text-xs rounded-sm bg-grayLight"
              >
                {u}
              </span>
            ))}
          </div>
        )}
        {userRole === "developer" && (
          <div>
            <h4 className="mb-2 text-base2">... or delete by type</h4>
            <div className="flex mb-2">
              {[
                "fragrances",
                "ingredients",
                "families",
                "perfumers",
                "posts",
                "news",
                "brands",
                "pages",
                "home",
                "all",
              ].map((type) => (
                <div key={type}>
                  <label className="mr-4 capitalize" htmlFor={type}>
                    <input
                      onChange={handleCheckbox}
                      className="mr-1"
                      type="checkbox"
                      name={type}
                      id={type}
                    />
                    {type}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="flex items-center">
          <button
            onClick={deleteCache}
            className={`px-4 py-1 rounded-sm bg-orange ${
              loading ? "opacity-60" : ""
            }`}
          >
            {loading ? "Loading..." : "Uncache"}
          </button>
          {feedback && <p className="ml-4 text-sm font-medium">{feedback}</p>}
        </div>
      </div>
      <button
        onClick={openPreview}
        className={`px-4 py-1 rounded-sm bg-grayLight ${
          loading ? "opacity-60" : ""
        }`}
      >
        View website in preview mode
      </button>
    </div>
  );
};

export default Cache;
