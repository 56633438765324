import { PttFieldError } from "../../types";

type ValidationType = (
  value: string | { [key: string]: unknown } | null,
  translatable?: boolean,
  languages?: { [key: string]: string },
  extraData?: { [key: string]: unknown }
) => boolean;

const not_empty: ValidationType = (value, translatable, languages) => {
  if (value === null) {
    return false
  }
  if (Array.isArray(value)) {
    return value.length > 0
  }
  const validation = (v: string) => typeof v === "string" && v !== "-1";
  if (translatable && typeof value === "object" && languages) {
    let valid = true;
    Object.keys(languages).forEach((language) => {
      if (value) {
        if (!validation(value[language] as string)) {
          valid = false;
        }
      }
    });
    return valid;
  } else {
    return validation(value as string as string);
  }
};

const email: ValidationType = (value, translatable, languages) => {
  const isValidEmail = (val: string) => {
    return String(val)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  if (value && translatable && typeof value === "object" && languages) {
    let valid = true;
    Object.keys(languages).forEach((language) => {
      if (!isValidEmail(value[language] as string)) {
        valid = false;
      }
    });
    return valid;
  } else {
    return !!isValidEmail(value as string);
  }
};

const not_blank: ValidationType = (value, translatable, languages) => {
  const validation = (v: string): boolean =>
    typeof v !== "undefined" && v.length > 0 && value !== "-1";
  if (translatable && typeof value === "object" && languages) {
    let valid = true;
    Object.keys(languages).forEach((language) => {
      if (value) {
        if (!validation(value[language] as string)) {
          valid = false;
        }
      }
    });
    return valid;
  }
  return typeof value === "string" ? validation(value) : false;
};

const password: ValidationType = (
  value,
  translatable,
  languages,
  extraData
) => {
  if (typeof value === "string" && value.length) {
    const repeatedValue = extraData?.repeated as string;
    if (value !== repeatedValue) return false;
  }
  return true;
};

const password_min_length: ValidationType = (
  value,
  translatable,
  languages,
  extraData
) => {
  if (extraData?.mode === "edit" && value === "") return true;
  return typeof value === "string" && value.length > 5;
};

const min_length: ValidationType = (value) => {
  return typeof value === "string" && value.length > 5;
};

const validationTypes: { [key: string]: ValidationType } = {
  not_empty,
  not_blank,
  password,
  password_min_length,
  min_length,
  email,
};

const Errors = (
  value: string | { [key: string]: unknown } | null,
  validations: PttFieldError[],
  translatable: boolean,
  languages?: { [key: string]: string },
  extraData: { [key: string]: unknown } = {}
): string[] => {
  const valid: string[] = [];
  if (validations && Array.isArray(validations)) {
    validations.forEach((validation) => {
      if (
        typeof validationTypes[validation] !== "undefined" &&
        !validationTypes[validation](value, translatable, languages, extraData)
      ) {
        valid.push(validation);
      }
    });
  }
  return valid;
};

export default Errors;
