import React, { useContext, useState } from 'react'
import { EditDataContext } from '../../contexts/EditDataContext'
import { PttFieldType } from '../../types'
import Field from './Field'
import { GlobalContext } from '../../contexts/GlobalContext'
import useLimitCharacterLength from '../../hooks/useLimitCharacterLength'

interface TextProps extends PttFieldType { }

type Translation = {
  text: string
  detectedSourceLang: string
  outputLanguage: string
}

type TranslationGroup = Translation[]


const Text = ({ field }: TextProps): React.ReactElement => {
  const { updateStored, getValue, getLanguage, getLanguages } = useContext(EditDataContext)
  const { getLiteral, getToken } = useContext(GlobalContext)

  const languages = getLanguages()
  const language = getLanguage({ field })
  const value = getValue({ field }) || {}
  const modelName = field.modelName as string
  const isTranslatable = field?.translatable || false

  const { colorClass, handleChange, currentLength, maxLength } = useLimitCharacterLength({ text: value[language] ?? "", maxLength: 60 })

  const [translating, setTranslating] = useState(false)
  const [errorTranslator, setErrorTranslator] = useState<string | null>(null)
  const [successTranslator, setSuccessTranslator] = useState<string | null>(null)

  const onChangeText = (v: string) => {
    value[language] = v
    updateStored(field, value)
  }
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (modelName === "PttSeo" && field?.parentField?.modelName === "BlogPost" && field?.name === 'title') {
      handleChange(e as any)
    }
    onChangeText(e.target.value)
  }

  const getOutputLanguages = (value: Record<string, string>) => {
    Object.keys(languages).forEach(lang => {
      if (!value[lang]) {
        value[lang] = ''
      }
    })
    return Object.keys(value).filter(lang => !value[lang]?.trim())
  }

  const getInputLanguage = (value: Record<string, string>) => {
    const languagesWithContent = Object.keys(value).filter(
      lang => value[lang] !== null && value[lang] !== undefined && value[lang].trim()
    )

    const preferredLanguages = ['en', 'es']
    for (const lang of preferredLanguages) {
      if (languagesWithContent.includes(lang)) {
        return lang
      }
    }

    return languagesWithContent.length > 0 ? languagesWithContent[0] : null
  }

  const handleTranslate = async () => {
    setTranslating(true)
    const inputLanguage = getInputLanguage(value) || 'en'
    const outputLanguages = getOutputLanguages(value)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}model/component-translator`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          texts: [value[inputLanguage]],
          inputLanguage,
          outputLanguages,
        }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const responseBody = await response.json()
      const data = JSON.parse(responseBody.body)
      const translations: TranslationGroup[] = data.translations

      if (translations && translations.length > 0) {
        const updatedValue = { ...value }

        translations.forEach((translationGroup: TranslationGroup, index: number) => {
          const targetLanguage = outputLanguages[index]
          const translatedText = translationGroup[0]?.text || ''

          if (targetLanguage) {
            updatedValue[targetLanguage] = translatedText
          }
        })

        updateStored(field, updatedValue)

        setSuccessTranslator('Text has been translated successfully')
      }
    } catch (error) {
      console.error('Translation failed:', error)
      setErrorTranslator('Error occurred while translating')
    } finally {
      setTranslating(false)
      setTimeout(() => setErrorTranslator(null), 2000)
      setTimeout(() => setSuccessTranslator(null), 2000)
    }
  }

  const shouldShowTranslationButton = (modelName === 'BlogPost' || field?.parentField?.modelName === 'BlogPost') && field?.type === 'text' && isTranslatable

  const translationButton = shouldShowTranslationButton && (
    <button
      onClick={handleTranslate}
      disabled={translating}
      className="px-4 py-2 mt-2 text-black bg-white border rounded border-grayLight"
    >
      {translating ? 'Translating...' : 'Translate'}
    </button>
  )

  const errorNotification = errorTranslator && (
    <div className="text-red-500">{errorTranslator}</div>
  )

  const successNotification = successTranslator && (
    <div className="text-green-500">{successTranslator}</div>
  )


  return (
    <Field field={field}>
      <div className="relative w-full">
        <input
          name={field?.name}
          className={`w-full px-2 py-1 bg-white outline-none placeholder-gray placeholder-opacity-50 border ${colorClass}`}
          placeholder={
            getLiteral(
              (field?.options as { [key: string]: unknown }).label as string,
              "manager_field_"
            ) || ""
          }
          value={`${value[language] || ''}`}
          onChange={handleTextChange}
        />
        {modelName === "PttSeo" && field?.parentField?.modelName === "BlogPost" && field?.name === 'title' && (
          <div className="flex justify-end mt-1 text-xs text-gray-600">
            {currentLength}/{maxLength}
          </div>
        )}
        {translationButton}
        {errorNotification}
        {successNotification}
      </div>
    </Field>
  )
}

export default Text