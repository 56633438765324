import { useContext, useState } from 'react'
import ReactMde from 'react-mde'
import * as Showdown from 'showdown'
import { EditDataContext } from '../../contexts/EditDataContext'
import { PttModelField } from '../../types'
import Field from './Field'
import useLimitCharacterLength from '../../hooks/useLimitCharacterLength'
import Cookies from 'js-cookie'
import { GlobalContext } from "../../contexts/GlobalContext"


interface TextareaType extends PttModelField {
  options: {
    label: string
    markdown: boolean
    autoTranslator?: boolean
    translatable?: boolean
  }
}

type Translation = {
  text: string
  detectedSourceLang: string
  outputLanguage: string
}

type TranslationGroup = Translation[]


const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

const listCommands = [
  ['bold', 'italic', 'link', 'unordered-list', 'ordered-list'],
]

const Textarea = ({ field }: { field: TextareaType }) => {
  const { updateStored, getValue, getErrors, getLanguages, getLanguage, showErrors } = useContext(EditDataContext)
  const { getToken } = useContext(GlobalContext)

  const languages = getLanguages()
  const language = getLanguage({ field })
  const value = getValue({ field })
  const errors = getErrors({ field })
  const modelName = field?.modelName as string
  const isTranslatable = field?.translatable || false

  const { colorClass, handleChange, currentLength, maxLength } = useLimitCharacterLength({ text: value[language] ?? '', maxLength: 160 })

  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')
  const [translating, setTranslating] = useState(false)
  const [errorTranslator, setErrorTranslator] = useState<string | null>(null)
  const [successTranslator, setSuccessTranslator] = useState<string | null>(null)

  const onChangeText = (v: string) => {
    value[language] = v
    updateStored(field, value)
  }

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (modelName === 'PttSeo' && field?.parentField?.modelName === 'BlogPost') {
      handleChange(e)
    }
    onChangeText(e.target.value)
  }

  const getOutputLanguages = (value: Record<string, string>) => {
    Object.keys(languages).forEach(lang => {
      if (!value[lang]) {
        value[lang] = ''
      }
    })
    return Object.keys(value).filter(lang => !value[lang]?.trim())
  }

  const getInputLanguage = (value: Record<string, string>) => {
    const languagesWithContent = Object.keys(value).filter(
      lang => value[lang] !== null && value[lang] !== undefined && value[lang].trim()
    )

    const preferredLanguages = ['en', 'es']
    for (const lang of preferredLanguages) {
      if (languagesWithContent.includes(lang)) {
        return lang
      }
    }

    return languagesWithContent.length > 0 ? languagesWithContent[0] : null
  }



  const handleTranslate = async () => {
    setTranslating(true)
    const inputLanguage = getInputLanguage(value) || 'en'
    const outputLanguages = getOutputLanguages(value)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}model/component-translator`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          texts: [value[inputLanguage]],
          inputLanguage,
          outputLanguages,
        }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const responseBody = await response.json()
      const data = JSON.parse(responseBody.body)
      const translations: TranslationGroup[] = data.translations

      if (translations && translations.length > 0) {
        const updatedValue = { ...value }

        translations.forEach((translationGroup: TranslationGroup, index: number) => {
          const targetLanguage = outputLanguages[index]
          const translatedText = translationGroup[0]?.text || ''

          if (targetLanguage) {
            updatedValue[targetLanguage] = translatedText
          }
        })

        updateStored(field, updatedValue)

        setSuccessTranslator('Text has been translated successfully')
      }
    } catch (error) {
      console.error('Translation failed:', error)
      setErrorTranslator('Error occurred while translating')
    } finally {
      setTranslating(false)
      setTimeout(() => setErrorTranslator(null), 2000)
      setTimeout(() => setSuccessTranslator(null), 2000)
    }
  }

  const shouldShowTranslationButton = (modelName === 'BlogPost' || field?.parentField?.modelName === 'BlogPost') && field?.type === 'textarea' && isTranslatable

  const translationButton = shouldShowTranslationButton && (
    <button
      onClick={handleTranslate}
      disabled={translating}
      className="px-4 py-2 mt-2 text-black bg-white border rounded border-grayLight"
    >
      {translating ? 'Translating...' : 'Translate'}
    </button>
  )

  const errorNotification = errorTranslator && (
    <div className="text-red-500">{errorTranslator}</div>
  )

  const successNotification = successTranslator && (
    <div className="text-green-500">{successTranslator}</div>
  )

  const textarea = field?.options?.markdown ? (
    <div className={`border border-grayLight ${showErrors && errors.length > 0 ? 'border-error' : ''}`}>
      <ReactMde
        toolbarCommands={listCommands}
        value={`${value[language] || ''}`}
        onChange={onChangeText}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={() => Promise.resolve(converter.makeHtml(`${value[language] || ''}`))}
      />
    </div>
  ) : (
    <div>
      <textarea
        name={field?.name}
        className={`border ${colorClass} w-full h-32 px-2 py-1 outline-none resize-y`}
        placeholder={field?.options?.label}
        value={`${value[language] || ''}`}
        onChange={handleTextareaChange}
      />
      {modelName === 'PttSeo' && field?.parentField?.modelName === 'BlogPost' && field?.name === 'text' && (
        <div className="flex justify-end mt-1 text-xs text-gray-600">
          {currentLength}/{maxLength}
        </div>
      )}
    </div>
  )

  return (
    <Field field={field}>
      {textarea}
      {translationButton}
      {errorNotification}
      {successNotification}
    </Field>
  )
}

export default Textarea