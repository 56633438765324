import React, { useContext } from "react";
import LanguageSelector from "./LanguageSelector";
import { PttModelField } from "../../types";
import { GlobalContext } from "../../contexts/GlobalContext";

type FieldProps = {
  field: PttModelField;
  children: React.ReactNode;
  hideLabel?: boolean;
  subtitle?: string;
};

export const Label = ({ field }: { field: PttModelField }) => {
  const { getLiteral } = useContext(GlobalContext);
  return (
    <>
      <label
        className={`font-light uppercase text-xs ${
          field.type === "checkbox" ? "cursor-pointer select-none " : "block"
        }`}
        htmlFor={field.name}
      >
        {getLiteral(
          (field?.options as { [key: string]: unknown }).label as string,
          "manager_field_"
        ) || ""}
      </label>
    </>
  );
};

const Field = ({ field, children, hideLabel = false, subtitle = '' }: FieldProps) => {
  return (
    <div>
      <div className="flex">
        {field.type !== "model" && !hideLabel && <Label field={field} />}
        {subtitle && <span className="ml-2 text-xs text-gray">{subtitle}</span>}
      </div>
      {field?.translatable ? (
        <LanguageSelector>{children}</LanguageSelector>
      ) : (
        children
      )}
    </div>
  );
};

export default Field;
