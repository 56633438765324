type ThrottleOptions = {
  callback: (...args: any[]) => void;
  time: number;
};

let timeout: NodeJS.Timeout | undefined
let lastCallBack: any

const throttle = ({ callback, time }: ThrottleOptions) => {

  return (...args: any[]) => {
    lastCallBack = callback
    if (timeout) return

    timeout = setTimeout(() => {
      lastCallBack && lastCallBack(...args)
      timeout = undefined
    }, time);
  };
};

export default throttle
