import React, { useContext, useEffect, useState } from 'react'
import { EditDataContext } from '../../contexts/EditDataContext'
import {
  SelectOptionType,
  PttModelField,
  ExternalSearchBody,
} from '../../types'
import Field from './Field'
import { GlobalContext } from '../../contexts/GlobalContext'
import fetchAPI from '../../lib/fetchAPI'
import throttle from '../../lib/throttle'
import Loader from '../Loader'

interface SelectType extends PttModelField {
  options: {
    label: string
    type: 'dynamic'
    multiple?: boolean
    model?: string
    options: { [key: string]: string }
  }
}

const SearchTypes: { [key: string]: { query: string; title: string } } = {
  perfume: {
    query: 'FindPerfumesSlugs',
    title: 'Perfume',
  },
  ingredient: {
    query: 'FindIngredientsSlugs',
    title: 'Ingredient',
  },
  family: {
    query: 'FindFamiliesSlugs',
    title: 'Family',
  },
}

const Search = ({ field }: { field: SelectType }) => {
  const { getToken } = useContext(GlobalContext)
  const { getLiteral } = useContext(GlobalContext)
  const { updateStored, getValue, getErrors, getLanguage, showErrors } =
    useContext(EditDataContext)

  const language = getLanguage({ field })
  const value = getValue({
    field,
    defaultValue: {},
  })

  const [query, setQuery] = useState(
    getValue({ field, defaultValue: '' })?.default?.type || ''
  )

  const errors = getErrors({ field })
  const [options, setOptions] = useState<SelectOptionType[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const onChangeSelect = (option: any) => {
    value[language].name = option.name
    value[language].type = query
    updateStored(field, { ...option, type: query })
    setOptions([])
  }

  const externalSearch = async ({ body }: { body: ExternalSearchBody }) => {
    try {
      setLoading(true)
      const { status, data } = await fetchAPI({
        url: 'external/query',
        body,
        token: getToken(),
      })

      if (status !== 200) throw new Error('Request error')

      setOptions(data.externalData)
    } catch (err) {
      console.log('🚀 ~ file: Search.tsx:66 ~ externalSearch ~ err:', err)
      setOptions([])
    } finally {
      setLoading(false)
    }
  }

  const onChangeText = async (e: React.ChangeEvent<HTMLInputElement>) => {
    value[language].name = e.target.value
    updateStored(field, value)

    if (e.target.value) {
      const body = {
        query: SearchTypes[query].query,
        variables: {
          search: {
            lang: 'en',
            search: e.target.value,
          },
        },
      }

      const throttledExternalSearch = throttle({
        callback: () => externalSearch({ body }),
        time: 800,
      })

      throttledExternalSearch()
    }
  }

  useEffect(() => {
    if (options.length !== 0 && !value[language]?.name) setOptions([])
  }, [options])

  return (
    <Field field={field}>
      <div className="flex">
        <div className='mr-2'>
          <select
            name="queryTypes"
            className="h-full px-2 py-1 bg-white border outline-none w-18 border-grayLight"
            onChange={(e) => {
              setQuery(e.target.value)
              const newValue = { ...value }
              newValue[language] = {}
              newValue[language].type = e.target.value
              updateStored(field, newValue)
            }}
            value={query}
          >
            <option value="">{getLiteral('type')}</option>
            {Object.keys(SearchTypes).map((key) => (
              <option key={key} value={key}>
                {SearchTypes[key].title}
              </option>
            ))}
          </select>
        </div>
        {query && (
          <div className="relative w-full">
            <div className="absolute right-0 pr-2 text-xl">&#x2315;</div>
            <input
              name={field.name}
              className={`w-full px-2 py-1 bg-white outline-none placeholder-gray placeholder-opacity-50 border ${
                showErrors && errors.length > 0
                  ? 'border-error'
                  : 'border-grayLight'
              }`}
              type="text"
              placeholder={
                getLiteral(
                  (field?.options as { [key: string]: unknown })
                    .label as string,
                  'manager_field_'
                ) || ''
              }
              value={`${
                value && value[language]?.name ? value[language]?.name : ''
              }`}
              onChange={onChangeText}
              autoComplete="off"
            />

            <ul
              className={`absolute z-50 w-full max-h-64 bg-white overflow-hidden overflow-y-auto ${
                loading || (options && options.length > 0)
                  ? ' border border-grayLight'
                  : ''
              } ${
                showErrors && errors.length > 0 ? 'border border-error' : ''
              } outline-none appearance-none`}
            >
              <Loader
                loading={loading}
                className="w-full px-2 py-1.5 border-grayLight hover:bg-grayLight outline-none appearance-none"
              >
                {options.map((option) => (
                  <li
                    className={`w-full cursor-pointer px-2 py-1.5 border-grayLight hover:bg-grayLight ${
                      showErrors && errors.length > 0
                        ? 'border border-error'
                        : ''
                    } outline-none appearance-none`}
                    key={option._id + option.name}
                    onClick={() => onChangeSelect(option)}
                  >
                    {option.name}
                  </li>
                ))}
              </Loader>
            </ul>
          </div>
        )}
      </div>
    </Field>
  )
}

export default Search
