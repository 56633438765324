import React, { ReactNode } from 'react';
import Wildcard from './Wildcard';
import { PttModelField } from '../../types';

type FormProps = {
  name: string;
  parentIsSorting?: boolean;
  parentField?: PttModelField;
  fields: PttModelField[];
  children?: ReactNode;
};

const Form = ({
  name,
  parentField,
  fields,
  children,
}: FormProps) => (
  <div className="grid grid-cols-4 gap-4">
    {fields.map((item: PttModelField, index: number) => (
      <Wildcard
        field={{
          ...item,
          modelName: name,
          parentField,
        }}
        key={`${item.name}-${index}`}
      />
    ))}
    {children} { }
  </div>
);

export default Form;
