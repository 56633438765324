import { useState, useEffect } from 'react'

type InputChangeEvent = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>

interface useLimitCharacterLengthProps {
  text: string
  maxLength?: number
}

const DEFAULT_COLOR = 'border-grayLight'

const useLimitCharacterLength = ({ text, maxLength = 60 }: useLimitCharacterLengthProps) => {
  const [colorClass, setColorClass] = useState(DEFAULT_COLOR)

  useEffect(() => {
    if (text.length >= maxLength - 10 && text.length < maxLength) {
      setColorClass('border-orangeDark')
    } else if (text.length === maxLength) {
      setColorClass('border-red')
    } else {
      setColorClass(DEFAULT_COLOR)
    }
  }, [text, maxLength])

  const handleChange = (e: InputChangeEvent) => {
    const newValue = e.target.value.slice(0, maxLength)
    e.target.value = newValue
  }

  return { colorClass, handleChange, currentLength: text.length, maxLength }
}

export default useLimitCharacterLength