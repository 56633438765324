import dayjs from "dayjs";

const printValue = ({
  item,
  key,
}: {
  item: { [key: string]: unknown };
  key: string;
}) => {
  const value: any = item[key];

  if (key.includes('.')) {
    const [firstKey, secondKey] = key.split('.')
    return item[secondKey]
  }

  if (key === 'publication.status') {
    return item['status']  
  }

  if (["perfumer", "perfumes"].includes(key)) {
    return value.name
  }

  if (Array.isArray(value)) {
    return value.map((v: any) => v || '---').join(', ')
  }

  const datetimeFields = ['datetime', 'publishDate']
  if (datetimeFields.includes(key)) {
    return dayjs(item[key] as string).format("DD/MM/YYYY HH:mm");
  }

  if (key.includes("date")) {
    return dayjs(item[key] as string).format("DD/MM/YYYY");
  }

  if (key.includes("time")) {
    return dayjs(item[key] as string).format("HH:mm");
  }

  if (typeof value === "boolean") {
    return value ? "✓" : "-";
  }

  return item[key];
};

export default printValue;
