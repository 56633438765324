import React, { useContext } from 'react'
import { EditDataContext } from '../../contexts/EditDataContext'
import { PttFieldType } from '../../types'
import Field from './Field'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

interface DateTimeProps extends PttFieldType {}

const Datetime = ({ field }: DateTimeProps) => {
  const { updateStored, getValue, getErrors, getLanguage, showErrors } =
    useContext(EditDataContext)

  const language = getLanguage({ field })
  const value = getValue({ field })
  const errors = getErrors({ field })

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    value[language] = dayjs(e.target.value).toDate()
    updateStored(field, value)
  }

  return (
    <Field
      field={field}
      subtitle={`(${dayjs.tz.guess()})`}
    >
      <input
        name={field.name}
        className={`w-full px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border-error' : ''
        } border border-grayLight outline-none placeholder-gray placeholder-opacity-50`}
        type="datetime-local"
        placeholder={`${field.options.label || ''}`}
        value={`${
          value[language]
            ? dayjs(value[language] as string).format('YYYY-MM-DDTHH:mm:ss')
            : ''
        }`}
        onChange={onChangeText}
      />
    </Field>
  )
}

export default Datetime
