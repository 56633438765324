import fetchAPI from "../lib/fetchAPI";
import Sortable from "sortablejs";

const dragAndDropSortList = ({
  appConfig,
  getToken,
  page,
  modelName,
  getData,
}: {
  appConfig: any;
  getToken: () => string;
  page: number;
  modelName: string;
  getData: () => void;
}) => {
  const itemsToSort = document.querySelector('tbody') as HTMLElement;
  new Sortable(itemsToSort, {
    animation: 150,
    ghostClass: 'bg-grayLighter',
    handle: '.handle',
    onEnd: async () => {
      const itemsPerPage = appConfig?.app?.itemsPerPage || 50
      let currentPosition = (page * itemsPerPage) + 1
      const itemsToUpate = [] as any
      const rows = Array.from(itemsToSort.querySelectorAll('tr'));
      rows.forEach(row => {
        if (row.getAttribute('id') === 'undefined') return
        if (row.getAttribute('data-position') === currentPosition.toString()) {
          currentPosition++
          return
        }
        const item = {
          _id: row.getAttribute('id'),
          position: currentPosition
        }
        itemsToUpate.push(item)
        currentPosition++
      })

      if (!itemsToUpate.length) return

      const body = {
        modelName,
        items: itemsToUpate,
      };

      const { valid } = await fetchAPI({
        url: "model/updatePosition",
        body,
        method: "PUT",
        token: getToken(),
      });

      if (!valid) {
        alert('Error updating position')
      }

      getData()
    },
  });
};

export default dragAndDropSortList;
