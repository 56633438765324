import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import Form from "../components/fields/Form";
import { EditDataContext } from "../contexts/EditDataContext";
import { GlobalContext } from "../contexts/GlobalContext";
import fetchAPI from "../lib/fetchAPI";

const Login = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const { getToken, setToken, getLiteral, appConfig } =
    useContext(GlobalContext);

  const { setData, storedData, hasErrors, setShowErrors, data } =
    useContext(EditDataContext);

  useEffect(() => {
    setData({
      stored: {
        modelName: "Login",
      },
      languages: {
        en: "en",
      },
      models: {
        Login: [
          {
            name: "email",
            type: "text",
            options: {
              label: "Email",
              style: {
                width: 4,
              },
            },
            validations: ["email"],
          },
          {
            name: "password",
            type: "password",
            options: {
              label: "Password",
              hideRepeat: true,
            },
          },
        ],
      },
    });
  }, [setData]);

  const login = async () => {
    if (hasErrors()) {
      setShowErrors(true);
      return;
    }

    if (!storedData) {
      return;
    }

    setShowErrors(false);
    setLoading(true);

    const { status, data } = await fetchAPI({
      url: "session/login",
      body: {
        email: storedData.email,
        password: storedData.password,
      },
      isPublic: true,
    });

    if (status !== 200) {
      setErrorMessage(data.error);
      setLoading(false);
      return;
    }

    setToken(data.token);
  };

  useEffect(() => {
    if (getToken() && appConfig?.user) {
      setLoading(false);
      navigate("/admin/");
    }
  }, [getToken, navigate, appConfig]);

  
  useEffect(() => {
    const checkEnterKey = (e: KeyboardEvent) => {
      if (e.code === "Enter") login()
    }
  
    window.addEventListener("keydown", checkEnterKey);
    return () => window.removeEventListener("keydown",checkEnterKey)    
  }, [])
  

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4 bg-white md:w-1/2 lg:w-1/4">
        <h1 className="text-xl font-medium">{getLiteral("login_title")}</h1>
        <h2 className="font-light text-gray">{getLiteral("login_text")}</h2>
        <div className="my-8">
          {data && <Form fields={data.models.Login} name={"Login"} />}
        </div>
        {errorMessage && (
          <div className="mb-4 text-red">{getLiteral(errorMessage)}</div>
        )}
        <div className="items-center justify-between md:flex">
          <Button onClick={login} theme="dark" loading={loading}>
            {getLiteral("login_cta")}
          </Button>
          <Link to={`/recovery`} className="text-xs cursor-pointer text-gray">
            {getLiteral("forgot_password_cta")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
