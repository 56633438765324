export const userHasPermission = ({
  appConfig,
  user,
  modelName,
  menuItem,
}: {
  appConfig: any
  user: any
  modelName: string
  menuItem: any
}) => {
  const item =
    menuItem ||
    appConfig?.menu?.find(
      (i: any) =>
        i?.items?.find((subitem: any) => subitem?.model === modelName) ||
        i?.model === modelName
    )
  if (item?.items) {
    const subitem = item.items.find(
      (subitem: any) => subitem.model === modelName
    )
    return subitem?.allowedRoles?.length
      ? subitem?.allowedRoles.includes(user?.role as string)
      : true
  } else {
    if (!item?.allowedRoles?.length) return true
    return item?.allowedRoles.includes(user?.role as string)
  }
}
