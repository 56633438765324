import React, { useState, useContext, useEffect } from 'react'
import Button from './Button'
import { GlobalContext } from '../contexts/GlobalContext'
import { EditDataContext } from '../contexts/EditDataContext'

type TextsToTranslate = Record<string, string>

type Props = {
  textsToTranslate: TextsToTranslate
  publicationId: string
  isCustomUrl: boolean
  updateTextsToTranslate: (updatedTexts: TextsToTranslate) => void
}

const ActionTranslateAll = ({
  textsToTranslate,
  publicationId,
  isCustomUrl,
  updateTextsToTranslate,
}: Props) => {
  const { storedData, getLanguages } = useContext(EditDataContext)
  const languages = getLanguages()
  const { getLiteral } = useContext(GlobalContext)
  const [translating, setTranslating] = useState(false)
  const [inputLanguage, setInputLanguage] = useState<string | null>(null)

  useEffect(() => {
    if (textsToTranslate?.en) {
      setInputLanguage('en')
    } else {
      setInputLanguage(
        Object.keys(textsToTranslate).find(
          (key) => textsToTranslate[key as keyof typeof textsToTranslate]
        ) || null
      )
    }
  }, [textsToTranslate])

  const handleTranslator = async () => {
    try {
      if (
        Object.keys(textsToTranslate).length === 0 ||
        Object.values(textsToTranslate).filter((i) => i && i !== '').length ===
        0
      ) {
        alert('Please, fill one language to translate')
        return
      }

      const confirmationMessage =
        'Are you sure you want to perform the translations?\n\nChanges will be automatically saved before translating.'
      if (!window.confirm(confirmationMessage)) {
        return
      }
      setTranslating(true)

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}model/translate-url`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            texts: textsToTranslate,
            inputLanguage,
            outputLanguages: Object.keys(languages),
            publicationId,
            isCustomUrl,
          }),
        }
      )

      const {
        body: { reloadPage },
      } = await response.json()

      if (reloadPage) {
        window.location.reload()
      }
    } catch (error) {
      console.error('An error occurred trying to translate the content', error)
    }
    setTranslating(false)
  }

  const handleDelete = () => {
    const updatedTexts = Object.keys(textsToTranslate).reduce((acc, lang) => {
      acc[lang] = ''
      return acc
    }, {} as TextsToTranslate)
    updateTextsToTranslate(updatedTexts)
  }

  return (
    <div className="col-span-4 md:col-span-4">
      {storedData && (
        <div className="w-full mt-2">
          <div className="flex flex-col w-full p-4 bg-white">
            <div className="flex items-center justify-between mb-4 text-xs font-medium uppercase select-none border-bottom">
              <p>Translate URL</p>
            </div>
            <div className="flex">
              <div className="flex items-center justify-between pr-2">
                <div className="flex">
                  <Button
                    theme="outline-dark"
                    onClick={handleDelete}
                    disabled={translating}
                  >
                    {getLiteral('delete_url')}
                  </Button>
                </div>
              </div>
              <div className="flex items-center justify-between pr-4">
                <div className="flex">
                  <Button
                    theme="outline-dark"
                    onClick={handleTranslator}
                    loading={translating}
                    disabled={translating}
                  >
                    {getLiteral('translate_url')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ActionTranslateAll
