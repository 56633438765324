import React, { useContext } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";

const LanguageSelector = ({ children }: { children: React.ReactNode }) => {
  const { getLanguages, getLanguage, setLanguage } =
    useContext(EditDataContext);

  const languages = getLanguages();
  const language = getLanguage({});

  return (
    <div>
      <div className="relative flex overflow-x-scroll -mb-1px">
        {languages &&
          Object.keys(languages).map((key) => (
            <div
              key={key}
              className={`-mb-px text-xs border border-grayLight pt-1 px-3 whitespace-nowrap cursor-pointer ${
                key === language
                  ? "border-b-white bg-white"
                  : "border-transparent border-b-grayLight"
              }`}
              onClick={() => setLanguage(key)}
            >
              {languages[key]}
            </div>
          ))}
      </div>
      {children}
    </div>
  );
};

export default LanguageSelector;
