import JSONEditor from "jsoneditor";
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { useContext, useEffect, useRef, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { PttFieldType } from "../../types";
import Field from "./Field";

interface JSONProps extends PttFieldType {}

const Json = ({ field }: JSONProps) => {
  const { updateStored, getValue, getErrors, getLanguage, showErrors } =
    useContext(EditDataContext);

  const language = getLanguage({ field });
  const value = getValue({ field });
  const errors = getErrors({ field });
  const fieldTemplates = field.options?.fieldTemplates

  const inputJson = useRef<HTMLTextAreaElement>(null);
  const jsonEditor = useRef<{ jsonEditor: JSONEditor }>(null);
  const [textareaValue, setTextareaValue] = useState<string>(
    JSON.stringify(value[language], undefined, 2)
  );

  const onChangeText = (v: { [key: string]: unknown }) => {
    value[language] = v;
    updateStored(field, value);
  };

  const textarea = (
    <div>
      <JsonEditor
        ref={jsonEditor}
        mode="code"
        allowedModes={["code", "tree"]}
        value={
          value && typeof value[language] !== "undefined"
            ? typeof value[language] === "string"
              ? JSON.parse(value[language] as string)
              : value[language]
            : {}
        }
        onChange={(e: {}) => {
          onChangeText(e);
        }}
        name={field.name}
        className={`w-full h-96 px-2 py-1 ${
          showErrors && errors.length > 0 ? "border border-error" : ""
        } rounded outline-none resize-y`}
        placeholder={field.options.label}
        onError={(e: any) => {
          console.log("Error", e);
        }}
        htmlElementProps={{ style: { height: 800 } }}
      />
    </div>
  );

  const [notificationText, setNotificationText] = useState("");
  const [showJsonTextArea, setShowJsonTextArea] = useState(false);

  useEffect(() => {
    if (notificationText) {
      setTimeout(() => {
        setNotificationText("");
      }, 3000);
    }
  }, [notificationText]);

  const copyJSON = () => {
    navigator.clipboard.writeText(
      JSON.stringify(value[language], undefined, 2)
    );
    setNotificationText(`Copied to clipboard`);
  };

  const insertJSON = () => {
    try {
      const data = JSON.parse(textareaValue);
      if (textareaValue) {
        if (jsonEditor.current) {
          jsonEditor.current.jsonEditor.set(data);
        }
        onChangeText(data as { [key: string]: unknown });
        setShowJsonTextArea(false);
      }
    } catch (e) {
      console.log("Invalid JSON");
    }
  };

  const addField = (data: any) => {
    const json = value[language] || {}
    if (!json.fields) json.fields = []
    
    json.fields.push(data)

    setTextareaValue(JSON.stringify(json, undefined, 2))
    jsonEditor.current?.jsonEditor.set(json);
    onChangeText(json as { [key: string]: unknown });
  } 

  return (
    <div>
      {showJsonTextArea && (
        <div
          className="fixed inset-0 z-40 flex items-center justify-center "
          style={{ backgroundColor: "rgba(255,255,255,.8)" }}
        >
          <div
            className="absolute inset-0 "
            onClick={() => setShowJsonTextArea(false)}
          />
          <div className="w-1/2 bg-white border rounded border-gray">
            <div className="relative flex flex-col items-end p-4 pt-2">
              <div className="w-full">
                <span
                  className="w-full cursor-pointer hover:text-primary"
                  onClick={copyJSON}
                >
                  Copy JSON
                </span>
                <span className="ml-2 text-xs cursor-default hover:text-black">
                  {notificationText}
                </span>
              </div>
              <textarea
                name=""
                id=""
                cols={30}
                rows={20}
                className="w-full p-2 mb-2 font-mono text-xs border border-black rounded"
                value={textareaValue}
                onChange={(e) => {
                  setTextareaValue(e.target.value);
                }}
                ref={inputJson}
              ></textarea>
              <div className="flex justify-end">
                <span
                  onClick={() => setShowJsonTextArea(false)}
                  className="px-2 py-0.5 mx-1 text-red border border-red rounded cursor-pointer  bg-white hover:bg-grayLighter"
                >
                  Cancel
                </span>
                <span
                  onClick={insertJSON}
                  className="px-2 py-0.5 mx-1 text-white rounded cursor-pointer  bg-primary hover:bg-black"
                >
                  Insert
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="relative">
        <div className="absolute top-0 right-0">
          <span
            className="h-full text-xs font-light uppercase cursor-pointer hover:text-primary"
            onClick={() => setShowJsonTextArea(true)}
          >
            View as JSON
          </span>
        </div>
        <Field field={field}>{textarea}</Field>
        { field.modelName === 'PttModel' && typeof fieldTemplates !== 'undefined' &&
          <div className="mt-2">
            <select
              className="px-2 py-1 border outline-none appearance-none cursor-pointer border-grayLight"
              onChange={(e) => {
                const selected = fieldTemplates.find((f: any) => f.label === e.target.value)
                if (selected) {
                  addField(selected.data)
                  e.target.value = '-1'
                }
              }}
              defaultValue="-1"
            >
              <option value="-1" disabled>Add field</option>
              {fieldTemplates.map((f: any) => {
                return <option className="cursor-pointer" value={f.label}>{f.label}</option>
              })}
            </select>
          </div>
        }
      </div>
    </div>
  );
};

export default Json;
