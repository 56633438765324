import React, { useContext, useEffect, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { GlobalContext } from "../../contexts/GlobalContext";
import upload from "../../lib/upload";
import { AppConfig, PttFieldType } from "../../types";
import Field, { Label } from "./Field";
import MediaViewer from "./MediaViewer";

interface FileProps extends PttFieldType {}

const File = ({ field }: FileProps) => {
  const { updateStored, getValue, getLanguage } = useContext(EditDataContext);
  const { appConfig, getToken } = useContext(GlobalContext);

  const language = getLanguage({ field });
  const value = getValue({ field });

  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showMediaView, setShowMediaViewer] = useState(false);
  const [fileUrl, setFileUrl] = useState(
    value && value[language]
      ? (value[language] as { [key: string]: string }).url
      : null
  );

  const updateValue = (v: { [key: string]: unknown } | null) => {
    value[language] = v;
    updateStored(field, value);
    setShowMediaViewer(false);
  };

  const remove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm("Confirm?")) {
      updateValue(null);
    }
  };

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.files.length === 0) return;

    setUploading(true);

    const token = getToken();
    if (!token) {
      return;
    }

    const fileInfo = await upload(
      "image",
      token as string,
      appConfig as AppConfig,
      e.dataTransfer.files[0]
    );

    if (!fileInfo) {
      setUploading(false);
    }

    updateValue(fileInfo);

    let timer = 0;
    const imageIsAvailable = setInterval(async () => {
      const res = await fetch(`${appConfig?.media?.public}files/${fileInfo?.name}.${fileInfo?.extension}`);
      if (res.status === 200) {
        clearInterval(imageIsAvailable);
        setUploading(false);
      } else {
        timer++;
        if (timer > 5) {
          setUploading(false);
          clearInterval(imageIsAvailable);
          alert("Upload request timeout");
        }
      }
    }, 3000);
  };

  useEffect(() => {
    if (value[language]) {
      setFileUrl(
        (value[language] as { [key: string]: unknown }).previewUrl as string
      );
    } else {
      setFileUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      <Label field={field} />
      <div className="p-4 border border-grayLight">
        <Field hideLabel={true} field={field}>
          <div className="max-w-input">
            <div
              className="relative flex items-center border cursor-pointer border-grayLight"
              onDragEnter={() => setDragging(true)}
              onDragLeave={() => setDragging(false)}
              onDragOver={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                onDrop(e);
                setDragging(false);
              }}
            >
              <div
                onClick={remove}
                className="absolute top-0 right-0 mt-1 mr-2"
              >
                ✕
              </div>
              <div className="relative flex items-center justify-center w-24 h-24 overflow-hidden border-r border-grayLight">
                {fileUrl && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-xs"
                    href={fileUrl}
                  >
                    View file
                  </a>
                )}
                {uploading && (
                  <img
                    className="absolute w-6"
                    alt="Preview"
                    src={`${process.env.PUBLIC_URL}/assets/images/spinner.gif`}
                  />
                )}
              </div>
              <div
                className={`${
                  dragging ? "" : ""
                } text-xs flex justify-center items-center flex-1`}
                onClick={() => {
                  if (!uploading) {
                    setShowMediaViewer(true);
                  }
                }}
              >
                <span className="text-center pointer-events-none">
                  {uploading && "Uploading..."}
                  {!uploading && !dragging && (
                    <span>
                      Drag file here
                      <span className="block text-xxs">
                        or view media gallery
                      </span>
                    </span>
                  )}
                  {dragging && !uploading && <span>Drop to upload</span>}
                </span>
              </div>
            </div>
            {showMediaView && (
              <MediaViewer
                type="file"
                value={value[language]}
                onChange={(v: { [key: string]: unknown }) => updateValue(v)}
                onClose={() => setShowMediaViewer(false)}
              />
            )}
          </div>
        </Field>
      </div>
    </div>
  );
};

export default File;
