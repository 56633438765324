import React, { useContext, useEffect, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import {
  SelectOptionType,
  PttModelField,
  ExternalSearchBody,
  WikiparfumSearchBody,
} from "../../types";
import Field from "./Field";
import { GlobalContext } from "../../contexts/GlobalContext";
import fetchAPI from "../../lib/fetchAPI";
import throttle from "../../lib/throttle";
import Loader from "../Loader";

interface SelectType extends PttModelField {
  options: {
    label: string;
    type: "dynamic";
    multiple?: boolean;
    model?: string;
    query: string;
    options: { [key: string]: string };
    isSearchWikiparfum: boolean;
  };
}

const Search = ({ field }: { field: SelectType }) => {
  const { getToken, logout, getLiteral } = useContext(GlobalContext);
  const { updateStored, getValue, getErrors, getLanguage, showErrors } =
    useContext(EditDataContext);

  const language = getLanguage({ field });
  const value = getValue({
    field,
    defaultValue: {},
  });

  const errors = getErrors({ field });
  const [options, setOptions] = useState<SelectOptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const isProductAffiliate = field.modelName === "ProductAffiliate"

  const getSearchValue = (item: any) => {
    let newSearchValue = item?.name || item?.title || ''
    if (item?.brand) {
      newSearchValue = `${newSearchValue?.split(item.brand)[0]?.trim()} - ${item.brand}`
    }
    return newSearchValue
  }
  
  useEffect(() => {
    setSearchValue(getSearchValue(value[language]))
  }, [])

  const onChangeSelect = (option: SelectOptionType) => {
    setSearchValue(getSearchValue(option))

    value[language] = option
    updateStored(field, option);
    setOptions([]);
  };

  const externalSearch = async ({ body }: { body: ExternalSearchBody }) => {
    try {
      setLoading(true);
      const { valid, data } = await fetchAPI({
        url: "external/query",
        body,
        token: getToken(),
      });

      if (!valid) logout();

      const parseExternalData = data.externalData.map(
        ({ id, name, slug, brand, image}: any) => ({
          id,
          name,
          slug,    
          ...(isProductAffiliate && {
            brand: brand?.name,
            image: image?.urls?.low
          })
        })
      );

      setOptions(parseExternalData);
    } catch (err) {
      console.log("🚀 ~ file: Search.tsx:66 ~ externalSearch ~ err:", err);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const wikiparfumSearch = async ({ body }: { body: WikiparfumSearchBody }) => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_SEARCH_URL;

      if (!url) {
        throw new Error("NEXT_PUBLIC_SEARCH_URL is not defined");
      }

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(body),
      });

      if (!res.ok) {
        if (res.status === 401) {
          logout();
        }
        throw new Error("Network response was not ok");
      }

      const data = await res.json();

      const parseExternalData = data.results.perfumes.map(
        ({ id, title, extra }: any) => ({
          id,
          name: extra?.perfumeName?.trim() || title.trim(),
          brand: extra?.brandName?.trim(),
        })
      );

      setOptions(parseExternalData);
    } catch (err) {
      console.log("🚀 ~ file: Search.tsx:66 ~ wikiparfumSearch ~ err:", err);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const onChangeText = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)

    if (e.target.value) {
      const body = field.options.isSearchWikiparfum
        ? {
          q: e.target.value,
          language: "en",
          limit: 5,
          include_extra: true,
          types: ["perfumes"],
        }
        : {
          query: field?.options?.query,
          variables: {
            search: {
              lang: "en",
              search: e.target.value,
            },
          },
        };

      const throttledSearch = throttle({
        callback: () => {
          if (field.options.isSearchWikiparfum) {
            wikiparfumSearch({ body: body as WikiparfumSearchBody });
          } else {
            externalSearch({ body: body as ExternalSearchBody });
          }
        },
        time: 800,
      });

      throttledSearch();
    }
  };


  return (
    <Field field={field}>
      <div>
        <div className="relative">
          <img
            className="absolute right-0 h-4 pr-2 bottom-2 text-base2"
            src="/search.png"
            alt="Search"
          />
          <input
            name={field.name}
            className={`w-full px-2 py-1 bg-white outline-none placeholder-gray placeholder-opacity-50 border ${showErrors && errors.length > 0 ? "border-error" : "border-grayLight"
              }`}
            type="text"
            placeholder={
              getLiteral(
                (field?.options as { [key: string]: unknown }).label as string,
                "manager_field_"
              ) || ""
            }
            onBlur={() => {
              setTimeout(() => {
                setOptions([])
              }, 500);
            }}
            value={searchValue}
            onChange={onChangeText}
            autoComplete="off"
          />

          <ul
            className={`absolute z-50 w-full max-h-64 bg-white overflow-hidden overflow-y-auto ${loading || (options && options.length > 0)
              ? " border border-grayLight"
              : ""
              } ${showErrors && errors.length > 0 ? "border border-error" : ""
              } outline-none appearance-none`}
          >
            <Loader
              loading={loading}
              className="w-full px-2 py-1.5 border-grayLight hover:bg-grayLight outline-none appearance-none"
            >
              {options.map((option) => (
                <li
                  className={`w-full cursor-pointer px-2 py-1.5 border-grayLight hover:bg-grayLight ${showErrors && errors.length > 0 ? "border border-error" : ""
                    } outline-none appearance-none`}
                  key={`${option.id}`}
                  onClick={() => onChangeSelect(option)}
                >
                  {option.brand ? `${option.name?.split(option.brand)[0]} - ${option.brand}` : option.name}
                  {isProductAffiliate && option.image && <img src={option.image} alt={option.name} className="h-8" />}
                </li>
              ))}
            </Loader>
          </ul>
        </div>
      </div>
    </Field>
  );
};

export default Search;
