import { Link } from "react-router-dom";

const Button = ({
  theme,
  children,
  loading,
  onClick,
  to,
  disabled,
}: {
  theme: "clear" | "dark" | "outline-green" | "outline-dark" | "danger" | "outline-gray"
  children: React.ReactNode;
  loading?: boolean;
  onClick?: () => void;
  to?: string;
  disabled?: boolean;
}) => {
  let extraClasses = "";
  switch (theme) {
    case "clear":
      extraClasses =
        "border-secondary bg-secondary text-primary hover:bg-primary hover:text-secondary";
      break;
    case "dark":
      extraClasses =
        "border-primary bg-primary text-secondary hover:bg-secondary hover:text-primary";
      break;
    case "outline-green":
      extraClasses =
        "border-public bg-secodary text-public hover:bg-public hover:text-secondary";
      break;
    case "outline-dark":
      extraClasses = "text-primary hover:bg-primary hover:text-secondary";
      break;
    case "danger":
      extraClasses =
        "hover:bg-error hover:text-secondary border-error text-error";
      break;
    case "outline-gray":
      extraClasses = "border-grayLight hover:bg-grayLight";
      break;
    default:
      break;
  }

  if (disabled) {
    extraClasses += " opacity-50 cursor-not-allowed pointer-events-none";
  }

  const className = `inline-block px-4 py-1 text-xs text-center border rounded cursor-pointer hover:border relative ${extraClasses}`;

  const loadingImage = (
    <>
      <img
        className="absolute w-4 h-4 -mt-2 -ml-2 left-1/2 top-1/2"
        src="/assets/images/spinner.gif"
        alt="..."
      />
    </>
  );

  const content = (
    <>
      {loading && loadingImage}
      <div className={`${loading ? "invisible" : ""}`}>{children}</div>
    </>
  );

  if (to) {
    return (
      <Link className={className} to={to}>
        {content}
      </Link>
    );
  }

  return (
    <span className={className} onClick={onClick}>
      {content}
    </span>
  );
};

export default Button;
