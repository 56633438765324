import { useContext, useState } from 'react'
import Button from './Button'
import { GlobalContext } from '../contexts/GlobalContext'

const TranslateLiterals = ({
  languages,
  token,
  logout,
  navigate,
}: {
  languages: any
  token: string
  logout: any
  navigate: any
}): JSX.Element => {
  const [translating, setTranslating] = useState(false)
  const [onlyTranslateDuplicates, setOnlyTranslateDuplicates] = useState(true)
  const [languagesToTranslate, setLanguagesToTranslate] = useState<Set<any>>(
    new Set()
  )
  const [successlanguagesToTranslate, setSuccesslanguagesToTranslate] =
    useState('')

  const { getLiteral } = useContext(GlobalContext)

  const handleLanguageChange = (e: any) => {
    const lang = e.target.value
    const isChecked = e.target.checked

    const newLanguagesToTranslate: Set<any> = new Set(languagesToTranslate)
    if (isChecked) {
      newLanguagesToTranslate.add(lang)
    } else {
      newLanguagesToTranslate.delete(lang)
    }
    setLanguagesToTranslate(newLanguagesToTranslate)
  }

  const translateLiterals = async () => {
    if (languagesToTranslate.size === 0) {
      return
    }

    setTranslating(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}model/translate-literals`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            languagesToTranslate: Array.from(languagesToTranslate),
            onlyTranslateDuplicates,
          }),
        }
      )

      const responseData = await response.json()
      setTranslating(false)
      if (responseData.status === 403) {
        logout()
        navigate('/login')
      } else {
        setSuccesslanguagesToTranslate('Translations done')
      }
      setTimeout(() => {
        setSuccesslanguagesToTranslate('')
      }, 3000)
    } catch (error) {
      console.error('Error occurred in translateLiterals:', error)
    }
  }

  const languageCheckboxes =
    languages !== undefined && languages !== null
      ? languages.map((lang: { code: string }) => {
          const label = lang?.code?.toUpperCase()
          const isChecked = languagesToTranslate?.has(lang?.code)

          return (
            <label
              key={lang?.code}
              className="flex items-center mr-3 cursor-pointer select-none"
            >
              <input
                type="checkbox"
                value={lang?.code}
                checked={isChecked}
                onChange={handleLanguageChange}
                className="mr-2 cursor-pointer accent-black"
              />
              {label}
            </label>
          )
        })
      : []

  return (
    <div className="w-full p-4 mb-4 bg-white border border-grayLight">
      <p className="wght-semibold">Translate literals</p>
      <p className="text-xs">Select language to translate literals:</p>
      <div className="flex flex-wrap">{languageCheckboxes}</div>
      <div className="flex items-center mt-2">
        <Button
          theme="outline-dark"
          onClick={translateLiterals}
          loading={translating}
          disabled={translating}
        >
          {getLiteral('translate_literals')}
        </Button>
        <label htmlFor="onlyTranslateDuplicates" className="flex ml-1">
          <input
            id="onlyTranslateDuplicates"
            type="checkbox"
            className="ml-2 accent-black"
            checked={onlyTranslateDuplicates}
            onChange={() =>
              setOnlyTranslateDuplicates(!onlyTranslateDuplicates)
            }
          />
          <span className="ml-1 text-xs cursor-pointer select-none">
            Only translate duplicates
          </span>
        </label>
      </div>
    </div>
  )
}

export default TranslateLiterals
