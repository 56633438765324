const translateGPT = async function ({
  text = [],
  inputLanguage,
  outputLanguage,
}: {
  text: string[]
  inputLanguage: string
  outputLanguage: string
}): Promise<string[]> {
  const promptGPT = `Translate the following texts from ${inputLanguage} to ${outputLanguage}, and response must be in an array in the same order as the input texts:
  ${JSON.stringify(text)}`

  const response = await fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    },
    body: JSON.stringify({
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system',
          content:
            'You are a helpful assistant that translates text from one language to another. Escape double quotes with two backslashes.',
        },
        {
          role: 'user',
          content: promptGPT,
        },
      ],
    }),
  })

  const data = await response.json()

  if (data?.error) {
    alert(data.error.message)
    return []
  }

  const textResult = data?.choices[0]?.message?.content
  const parsedTextResult = JSON.parse(textResult)

  return parsedTextResult
}

export default translateGPT
