import { useState, useEffect } from "react";

const MAX_ATTEMPTS = 5;

function useImageLoaded(src?: string) {
  const [loaded, setLoaded] = useState(false);
  const [attempt, setAttempt] = useState(0);

  useEffect(() => {
    if (!src) return;

    const loadImage = () => {
      const mainImage = new Image();

      mainImage.onload = () => {
        setLoaded(true);
      };

      mainImage.onerror = () => {
        if (attempt < MAX_ATTEMPTS) {
          setTimeout(() => {
            setAttempt(attempt + 1);
          }, 3000 * (attempt + 1));
        } else {
          setLoaded(true);
        }
      };

      mainImage.src = src;

      if (mainImage.complete) {
        setLoaded(true);
      }
    };

    if (attempt < MAX_ATTEMPTS && !loaded) {
      loadImage();
    }
  }, [src, attempt, loaded]);

  if (!src) return false;

  return loaded;
}

export default useImageLoaded;
