import React, { ReactNode } from 'react';

interface LoaderProps {
  loading: boolean;
  text?: string;
  className?: string;
  children?: ReactNode;
}

const Loader: React.FC<LoaderProps> = ({
  loading = false,
  text = 'Loading...',
  className,
  children,
}) => {
  if (!loading) return <>{children}</>;

  return <div className={className}>{text}</div>;
};

export default Loader;
