/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react'
import fetchAPI from '../lib/fetchAPI'
import { GlobalContext } from '../contexts/GlobalContext'
import translateGPT from '../lib/translateGPT'

type Perfumer = {
  id: string
  name: string
  teaser: string
  bio: string
}

const TranslationsTest = () => {
  const { getToken, logout, getUser } = useContext(GlobalContext)
  const user = getUser()
  const [loading, setLoading] = useState(false)
  const [fetchingGPT, setFetchingGPT] = useState(false)
  const [data, setData] = useState<Perfumer[]>([])
  const [selectedPerfumer, setSelectedPerfumer] = useState('')
  const [originLanguage, setOriginLanguage] = useState('en')
  const [translationLanguage, setTranslationLanguage] = useState('es')
  const [responseTranslations, setResponseTranslations] = useState<string[]>([])

  const languages: Record<string, string> = {
    en: 'English',
    es: 'Español',
    cs: 'Český',
    de: 'Deutsch',
    pt: 'Português',
    zh: '中文',
    ca: 'Català',
    fr: 'Français',
    it: 'Italiano',
    tr: 'Türkçe',
    ru: 'Русский',
    'zh-ht': '中文',
    ar: 'العربية',
  }

  const get = async () => {
    setLoading(true)
    const { valid, data } = await fetchAPI({
      url: 'model/get-perfumers',
      body: { lang: originLanguage },
      token: getToken(),
    })

    if (!valid) logout()

    setData(data?.items)
    if (!selectedPerfumer) {
      setSelectedPerfumer(data?.items[0]?.id)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!['admin', 'developer'].includes(user?.role as string)) {
      window.location.href = '/'
    } else {
      get()
    }
  }, [originLanguage])

  useEffect(() => {
    setResponseTranslations([])
  }, [selectedPerfumer])

  const translate = async () => {
    const textToTranslate = [
      data.find((item) => item.id === selectedPerfumer)?.teaser || '',
      data.find((item) => item.id === selectedPerfumer)?.bio || '',
    ]

    setFetchingGPT(true)
    const response: string[] = await translateGPT({
      text: textToTranslate,
      inputLanguage: originLanguage,
      outputLanguage: translationLanguage,
    })
    setFetchingGPT(false)
    if (response?.length) {
      setResponseTranslations(response)
    }
  }

  return (
    <div className="flex h-screen px-12 py-6">
      <div className="w-full">
        <h1 className="font-medium text-xxl">Translations Test</h1>
        <h2 className="font-light text-gray">
          Select a perfumer and translate the text to another language. You can
          select the original language and the language to translate. Then,
          click on the Translate button to get the AI response.
        </h2>
        {loading && <div className="my-8 text-gray">Loading...</div>}
        {!loading && data && data.length === 0 && (
          <div className="my-8">Nothing to list</div>
        )}
        {!loading && data && data.length > 0 && (
          <div className="my-8">
            <div className="flex items-end justify-between w-full mb-12">
              <div className="flex flex-col w-1/2 ">
                <label htmlFor="select-perfumer" className="mb-2">
                  Select perfumer
                </label>
                <select
                  id="select-perfumer"
                  className="w-auto px-2 py-1 border outline-none appearance-none cursor-pointer bg-grayLighter border-grayLight"
                  onChange={(e) => {
                    setSelectedPerfumer(e.target.value)
                  }}
                  defaultValue={data[0].id}
                >
                  {data?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="ml-2">
                <button
                  onClick={translate}
                  disabled={fetchingGPT}
                  className="px-4 py-2 text-sm text-white rounded-sm bg-primary"
                >
                  {fetchingGPT ? 'Fetching...' : 'Translate'}
                </button>
              </div>
            </div>

            <div className="flex w-full mb-4">
              <div className="w-1/2 pr-4">
                <label htmlFor="origin-language" className="mb-2">
                  Origin language
                </label>
                <select
                  id="origin-language"
                  className="w-full px-2 py-1 border outline-none appearance-none cursor-pointer bg-grayLighter border-grayLight"
                  onChange={(e) => {
                    setOriginLanguage(e.target.value)
                  }}
                  defaultValue={originLanguage}
                >
                  {Object.keys(languages).map((language) => (
                    <option key={language} value={language}>
                      {languages[language]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2 pr-4">
                <label htmlFor="translation-language" className="mb-2">
                  Translation language
                </label>
                <select
                  id="translation-language"
                  className="w-full px-2 py-1 border outline-none appearance-none cursor-pointer bg-grayLighter border-grayLight"
                  onChange={(e) => {
                    setTranslationLanguage(e.target.value)
                  }}
                  defaultValue={'es'}
                >
                  {Object.keys(languages).map((language) => (
                    <option key={language} value={language}>
                      {languages[language]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {selectedPerfumer && (
              <div>
                <div className="flex w-full mb-4">
                  <div className="w-1/2 pr-4">
                    <label htmlFor="translate" className="mb-2">
                      Teaser:
                    </label>
                    <textarea
                      id="translate"
                      className="w-full px-2 py-1 overflow-scroll border outline-none appearance-none cursor-pointer h-52 border-grayLight"
                      disabled
                      value={
                        data.find((item) => item.id === selectedPerfumer)
                          ?.teaser
                      }
                    />
                  </div>
                  <div className="w-1/2 pr-4">
                    <label htmlFor="translate" className="mb-2">
                      Translation:
                    </label>
                    <textarea
                      id="translate"
                      className="w-full px-2 py-1 overflow-scroll border outline-none appearance-none cursor-pointer h-52 border-grayLight"
                      disabled
                      value={!fetchingGPT ? responseTranslations[0] || '' : 'Preparing translation...' }
                    />
                  </div>
                </div>

                <div className="flex w-full mb-4">
                  <div className="w-1/2 pr-4">
                    <label htmlFor="translate" className="mb-2">
                      Bio:
                    </label>
                    <textarea
                      id="translate"
                      className="w-full px-2 py-1 overflow-scroll border outline-none appearance-none cursor-pointer h-52 border-grayLight"
                      disabled
                      value={
                        data.find((item) => item.id === selectedPerfumer)?.bio
                      }
                    />
                  </div>
                  <div className="w-1/2 pr-4">
                    <label htmlFor="translate" className="mb-2">
                      Translation:
                    </label>
                    <textarea
                      id="translate"
                      className="w-full px-2 py-1 overflow-scroll border outline-none appearance-none cursor-pointer h-52 border-grayLight"
                      disabled
                      value={!fetchingGPT ? responseTranslations[1] || '' : 'Preparing translation...' }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default TranslationsTest
