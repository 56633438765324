import React from 'react'
import { useContext } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'
import Button from './Button'

const SelectLanguageToDelete = ({
  languages,
  languagesToDelete,
  setLanguagesToDelete,
  onDeleteContent,
  deleting,
  onlyDeleteDuplicates,
  setOnlyDeleteDuplicates,
}: {
  languages: any
  languagesToDelete: any
  setLanguagesToDelete: any
  onDeleteContent: any
  deleting: any
  onlyDeleteDuplicates: any
  setOnlyDeleteDuplicates: any
}) => {
  const { getLiteral } = useContext(GlobalContext)
  const handleLanguageChange = (e: any) => {
    const lang = e.target.value
    const isChecked = e.target.checked

    if (isChecked) {
      setLanguagesToDelete((prevSelected: any) => [...prevSelected, lang])
    } else {
      setLanguagesToDelete((prevSelected: any[]) =>
        prevSelected.filter((selectedLang) => selectedLang !== lang)
      )
    }
  }

  const languageCheckboxes =
    languages !== undefined && languages !== null
      ? Object.keys(languages).map((lang) => {
          const label = lang?.toUpperCase()
          const isChecked = languagesToDelete?.includes(lang)

          return (
            <label
              key={lang}
              className="flex items-center mr-3 cursor-pointer select-none"
            >
              <input
                type="checkbox"
                value={lang}
                checked={isChecked}
                onChange={handleLanguageChange}
                className="mr-2 cursor-pointer accent-black"
              />
              {label}
            </label>
          )
        })
      : []

  return (
    <div className="pl-4">
      <div className="wght-semibold">Delete content</div>
      <div className="text-xs">Select language to delete content:</div>
      <div className="flex flex-wrap">{languageCheckboxes}</div>
      <div className="flex items-center mt-2">
        <Button
          loading={deleting}
          disabled={deleting}
          theme="danger"
          onClick={onDeleteContent}
        >
          {getLiteral('delete_content')}
        </Button>
        <label htmlFor="onlyDeleteDuplicates" className="ml-1">
          <input
            id="onlyDeleteDuplicates"
            type="checkbox"
            className="ml-2 accent-black"
            checked={onlyDeleteDuplicates}
            onChange={() => setOnlyDeleteDuplicates(!onlyDeleteDuplicates)}
          />
          <span className="ml-1 text-xs cursor-pointer select-none">
            Only delete duplicates
          </span>
        </label>
      </div>
    </div>
  )
}

export default SelectLanguageToDelete
